var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"TrendBox"},[_c('Card',{attrs:{"elevation":1}},[_c('div',{staticClass:"TrendBox__content",attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"TrendBox__label"},[_c('i',{staticClass:"icon-graph-line"}),_vm._v(" RENDIMENTO")]),_c('div',{staticClass:"TrendBox__TimeSwitch pull-right",attrs:{"id":"timeswitch"}},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('button',{staticClass:"btn",class:[
							_vm.selectedTime == 'inception'
								? 'selected'
								: 'unselected',
						],attrs:{"type":"button"},on:{"click":function($event){return _vm.setTime('inception', $event)}}},[_vm._v(" Inizio ")]),_c('button',{staticClass:"btn",class:[
							_vm.selectedTime == 'ytd'
								? 'selected'
								: 'unselected',
						],attrs:{"type":"button"},on:{"click":function($event){return _vm.setTime('ytd', $event)}}},[_vm._v(" YTD ")]),_c('button',{staticClass:"btn",class:[
							_vm.selectedTime == '3m'
								? 'selected'
								: 'unselected',
						],attrs:{"type":"button"},on:{"click":function($event){return _vm.setTime('3m', $event)}}},[_vm._v(" 3 mesi ")])])]),(_vm.points != null)?_c('BaseLineChart',{attrs:{"points":_vm.points}}):_vm._e(),_c('div',{staticStyle:{"margin-top":"35px"}},[_c('BaseTable',{attrs:{"columns":_vm.table_columns,"rows":_vm.rows,"lastline":_vm.tot_row,"performanceInterval":_vm.performanceInterval}})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }