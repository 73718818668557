import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
// import router from '../router/onboarding/router'

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'

export const http = axios.create({
    baseURL: 'http://localhost:3000',
});

export default new Vuex.Store({
    actions: {},
    data: {
        mandato: null,
        soggetto: null,
        csrfToken: null,
        customerName: null,
        usd:false,
        il_tuo_anno: false,
        contractStatus: 'INACTIVE',
    },
    getters: {
        get_il_tuo_anno: state => {
            return state.il_tuo_anno;
        },
        getSelectedTime: state => {
            return state.selectedTime;
        },
        isPanelOpened: state => {
            return state.openPanel;
        },
        isDDOpened: state => {
            return state.ddOpened;
        },
        getPanelPage: state => {
            return state.panelPage;
        },
        getCurrentContract: state => {
            return state.currentContract;
        },
        getInvestments: state => {
            return state.investments;
        },
    },
    mutations: {
        setSelectedTime(state, value) {
            this.state.selectedTime = value;
        },
        setPanelOpened(state, value) {
            this.state.openPanel = value;
        },
        setDDOpened(state, value) {
            this.state.ddOpened = value;
        },
        setPanelPage(state, value) {
            this.state.panelPage = value;
        },
        setCurrentContract(state, value) {
            this.state.currentContract = value;
            this.mandato = this.state.currentContract.cod;
            this.contractStatus = this.state.currentContract.status;
        },
        setInvestments(state, values) {
            this.state.investments = values;
        },
        set_il_tuo_anno(state,value){
            this.state.il_tuo_anno = value;
        }
    },
    setters: {},
    state: {
        selectedTime: 'inception',
        openPanel: false,
        ddOpened: '',
        panelPage: null,
        currentContract: null,
        investments: [],
        il_tuo_anno: false
    },
});
