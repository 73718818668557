import store from '../../store/onboarding';
// import {autocompleteCities} from '../../services/utilities'
import { setupValidation } from '../../services/validation';
import _ from 'lodash';
import i18n from 'i18next';
import $ from 'jquery';
import Autocomplete from 'vue2-autocomplete-js';
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css');

export default {
    beforeCreate() {
        store.state.isLoading = true;
        var form = this.$options.name;
        store.commit('CLEAN_FORM', { form });
    },
    components: { Autocomplete },
    computed: {
        compileButton: () => i18n.t('General.compileButton'),
        current_cosigner: () => store.state.current_cosigner,
        documentNumber: () =>
            i18n.t('Components.DocumentsInformations.documentNumber'),
        documentType: () =>
            i18n.t('Components.DocumentsInformations.documentType'),
        documents: () => store.state.documents,
        editButton: () => i18n.t('General.editButton'),
        expiringDate: () =>
            i18n.t('Components.DocumentsInformations.expiringDate'),
        isLoading: () => store.state.isLoading,
        previousFormButton: () => i18n.t('General.previousFormButton'),
        releasingCity: () =>
            i18n.t('Components.DocumentsInformations.releasingCity'),
        releasingDate: () =>
            i18n.t('Components.DocumentsInformations.releasingDate'),
        releasingDistrict: () =>
            i18n.t('Components.DocumentsInformations.releasingDistrict'),
        saveButton: () => i18n.t('General.saveButton'),
        document_type_id: {
            get: function() {
                var doc_type = null;
                Array.from(store.state.options.document_type).forEach(function(
                    item
                ) {
                    if (store.state.documents.id_document_type == item.value) {
                        doc_type = item.name;
                    }
                });
                return doc_type;
            },
            set: function(value) {
                this.document_type_id = value;
            },
        },
        release_month: {
            get: function() {
                var release_month = null;
                Array.from(store.state.options.months).forEach(function(item) {
                    if (
                        store.state.documents.id_document_releasing_month ==
                        item[1]
                    ) {
                        release_month = item[0];
                    }
                });
                return release_month;
            },
            set: function(value) {
                this.release_month = value;
            },
        },
        expire_month: {
            get: function() {
                var expire_month = null;
                Array.from(store.state.options.months).forEach(function(item) {
                    if (
                        store.state.documents.id_document_expiring_month ==
                        item[1]
                    ) {
                        expire_month = item[0];
                    }
                });
                return expire_month;
            },
            set: function(value) {
                this.expire_month = value;
            },
        },
    },
    created() {
        this.months = [];
        this.document_type = [];
        var that = this;
        Array.from(store.state.options.months).forEach(function(item) {
            that.months.push({ label: item[0], value: item[1] });
        });
        Array.from(store.state.options.document_type).forEach(function(item) {
            that.document_type.push({ label: item.name, value: item.value });
        });
        const cosigner = this.$store.state.current_cosigner;
        const form = this.$options.name;
        this.$store.dispatch('get_customer_data', { cosigner, form }).then(
            // eslint-disable-next-line no-unused-vars
            response => {
                store.state.isLoading = false;
                if (
                    store.state.documents.id_document_releasing_city !=
                    undefined
                ) {
                    const savedCity =
                        store.state.documents.id_document_releasing_city;
                    this.$refs.autocompletez.setValue(savedCity);
                }
            },
            error => {
                store.state.isLoading = false;
                console.log(error);
            }
        );
        this.$store.state.documents.id_document_releasing_country = 'I';
    },
    data: function() {
        return {
            city_search_url: '/onboarding/city',
            cosigners: _.cloneDeep(store.state.cosigners),
            with_beneficiary: _.cloneDeep(store.state.with_beneficiary),
            days: _.cloneDeep(store.state.options.days),
            document_type: [],
            months: [],
            show_error: false,
            years_expiring_doc: _.cloneDeep(
                store.state.options.years_expiring_doc
            ),
            years_releasing_doc: _.cloneDeep(
                store.state.options.years_releasing_doc
            ),
        };
    },
    destroyed() {
        sessionStorage.setItem(
            'breadcrumb',
            JSON.stringify({ cosigner: store.state.current_cosigner, step: 5 })
        );
    },
    methods: {
        // isFormCompleted() {
        //     for (var x in this.$store.state.documents) {
        //         if (
        //             this.$store.state.documents[x] == undefined ||
        //             this.$store.state.documents[x] == null ||
        //             this.$store.state.documents[x] == ''
        //         ) {
        //             return false;
        //         }
        //     }
        //     return true;
        // },
        processJSON(data) {
            if (data.length > 0) {
                var results = [];
                data.forEach(function(item) {
                    let longCity = item.city + ' (' + item.district + ')';
                    let notItalianCity = item.city + ', ' + item.country;
                    results.push({
                        city_ref: item.city_ref,
                        long_city: longCity,
                        city:
                            item.country == 'Italia'
                                ? longCity
                                : notItalianCity,
                    });
                });
                return results;
            }
            return [];
        },
        handleSelect(data) {
            console.log("what's happen: ", data);
            const selectedCity = data.city;
            this.$store.state.documents.id_document_releasing_city = selectedCity;
            this.$store.state.documents.id_document_releasing_city_ref =
                data.city_ref;
            this.$refs.autocompletez.setValue(data.long_city);
        },
        setValue(data) {
            this.$refs.autocompletez.setValue(data.long_city);
        },
        setActiveDocType(option) {
            if (option.value)
                store.state.documents.id_document_type = option.value;
        },
        setActiveExpireMonth(option) {
            if (option.value)
                store.state.documents.id_document_expiring_month = option.value;
        },
        setActiveReleaseMonth(option) {
            if (option.value)
                store.state.documents.id_document_releasing_month =
                    option.value;
        },
        nextForm() {
            const form = 'docinfo';
            const cosigner = store.state.current_cosigner;
            const property = 'documents';
            $('.on-an__form')
                .parsley()
                .isValid()
                ? store
                      .dispatch('save_data_async', { cosigner, form, property })
                      .then(response => {
                          response.status !== 200
                              ? (this.show_error =
                                    true &&
                                    this.$raven.captureException(
                                        new Error(
                                            'The response status id :' +
                                                response.status
                                        )
                                    ))
                              : this.$router.push({ name: 'carica-documenti' });
                      })
                      .catch(error => {
                          this.$raven.captureException(
                              new Error('The error is :' + error)
                          );
                          this.show_error = true;
                      })
                : $('.on-an__form')
                      .parsley()
                      .validate();
        },
        back_to_previous_form() {
            const form = 'docinfo';
            store.dispatch('back_to_previous_form', { form });
        },
    },
    mounted() {
        // Init validation on the component fields
        setupValidation();
    },
    name: 'documents',
    template: '</documents-informations>',
};
