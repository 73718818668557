import '../../services/i18n/i18n';
import store from '../../store/privateArea';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import AmCharts from 'amcharts3';
// eslint-disable-next-line no-unused-vars
import AmSerial from 'amcharts3/amcharts/serial';

const BASE_URL = '/wm/api/v1/mandato/';

window.AmCharts.monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
];

window.AmCharts.shortMonthNames = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
];

export default {
    props: {
        tmw: null,
    },
    data() {
        return {
            url: null,
            horizont: 'ytd',
            loading: true,
            points: [],
            contract: null,
            chart: null,
        };
    },
    methods: {
        buildUrl() {
            this.url = BASE_URL + store.mandato;
            if (this.tmw) {
                this.url += '/horizont/' + this.horizont + '/returns_tmw';
            } else {
                this.url += '/horizont/' + this.horizont + '/returns';
            }
        },
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.points = response.data.results;
                    this.points[this.points.length - 1].customBullet = 'black';
                    this.loading = false;
                    this.drawLineChart();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        drawLineChart() {
            if (this.chart != null) {
                this.chart.dataProvider = this.points;
                this.chart.validateData();
                this.chart.animateAgain();
            } else {
                //var max = get_max(this.points);

                this.chart = window.AmCharts.makeChart('chartdiv', {
                    pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
                    path: '/static/fe/amcharts',
                    hideCredits: true,
                    type: 'serial',
                    theme: 'light',
                    startDuration: 0.35,
                    startEffect: 'easeOutSine',
                    marginRight: 20,
                    marginLeft: 0,
                    autoMarginOffset: 0,
                    // fontFamily: 'Inter Ui',
                    fontSize: 12,
                    mouseWheelZoomEnabled: true,
                    dataDateFormat: 'YYYY-MM-DD',
                    valueAxes: [
                        {
                            id: 'v1',
                            axisAlpha: 0,
                            position: 'left',
                            ignoreAxisWidth: false,
                            dashLength: 3,
                            labelFunction: function(value) {
                                return (
                                    window.AmCharts.formatNumber(
                                        value,
                                        {
                                            precision: 2,
                                            decimalSeparator: ',',
                                            thousandsSeparator: '.',
                                        },
                                        2
                                    ) + '%'
                                );
                            },
                        },
                    ],
                    balloon: {
                        borderThickness: 1,
                        shadowAlpha: 0,
                    },
                    graphs: [
                        {
                            id: 'g1',
                            balloon: {
                                adjustBorderColor: true,
                                borderAlpha: 1,
                                borderThickness: 1.5,
                                borderColor: '#ffffff',
                                color: '#1d5b8f',
                                cornerRadius: 1,
                                fillColor: '#ffffff',
                                horizontalPadding: 10,
                                showBullet: false,
                                verticalPadding: 5,
                            },
                            bullet: 'none',
                            bulletBorderAlpha: 1,
                            bulletColor: '#FFFFFF',
                            bulletSize: 4,
                            hideBulletsCount: this.points.length,
                            lineThickness: 1,
                            fillAlphas: 0.3,
                            title: 'red line',
                            useLineColorForBulletBorder: true,
                            valueField: 'value',
                            color: '#1d5b8f',
                            lineColor: '#1d5b8f',
                            balloonText:
                                "<span style='font-size:14px;color:black;'>[[date]]</span><br><span style='font-size:24px;'>[[value]]</span>",
                            balloonFunction: function(item, graph) {
                                var result = graph.balloonText;
                                for (var key in item.dataContext) {
                                    var res = item.dataContext[key];
                                    if (key === 'date') {
                                        res = window.AmCharts.formatDate(
                                            new Date(res),
                                            'DD MMMM YYYY'
                                        );
                                    } else {
                                        res = res + '%';
                                    }
                                    result = result.replace(
                                        '[[' + key + ']]',
                                        res
                                    );
                                }
                                return result;
                            },
                        },
                    ],
                    chartScrollbar: {
                        graph: 'g1',
                        oppositeAxis: false,
                        offset: 30,
                        scrollbarHeight: 80,
                        backgroundAlpha: 0,
                        selectedBackgroundAlpha: 0.1,
                        selectedBackgroundColor: '#888888',
                        graphFillAlpha: 0,
                        graphLineAlpha: 0.5,
                        selectedGraphFillAlpha: 0,
                        selectedGraphLineAlpha: 1,
                        autoGridCount: true,
                        color: '#AAAAAA',
                    },
                    chartCursor: {
                        pan: true,
                        valueLineEnabled: true,
                        valueLineBalloonEnabled: true,
                        cursorAlpha: 1,
                        cursorColor: '#258cbb',
                        limitToGraph: 'g1',
                        valueLineAlpha: 0.2,
                        valueZoomable: true,
                    },
                    categoryField: 'date',
                    categoryAxis: {
                        parseDates: true,
                        dashLength: 1,
                        minorGridEnabled: true,
                        gridAlpha: 0,
                        gridThickness: 0,
                    },
                    export: {
                        enabled: true,
                    },
                    dataProvider: this.points,
                });
            }
        },
    },
    computed: {
        selectedTime() {
            return store.getters.getSelectedTime;
        },
        getCurrentContract() {
            return store.getters.getCurrentContract;
        },
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        tmw: function(newVal, oldVal) {
            this.buildUrl();
            this.fetchData();
        },
        selectedTime: function(val) {
            this.horizont = val;
            this.buildUrl();
            this.fetchData();
        },
        getCurrentContract: function(val) {
            this.contract = val;
            this.buildUrl();
            this.fetchData();
        },
    },
    created() {
        this.contract = store.getters.getCurrentContract;
        this.buildUrl();
        this.fetchData();
    },
};
