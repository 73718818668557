import '../../services/i18n/i18n';
import router from '../../router/privateArea/router';
import store from '../../store/privateArea';
import axios from 'axios';
import color from 'color';
import moment from 'moment';

const BASE_URL = '/wm/api/v1/mandato/';
const COMPOSITION_URL = '/composition/';
const RISK_URL = '/risk';
const PERFORMANCE_URL = '/horizont/YTD/performance/';

const FAKE_DATA =
    '{' +
    '"ref_date":"2018-01-01","assets":[{"spese":"0","color":"#315D72","peso":"0",' +
    '"nome":"Obbligazionario Breve Termine","valore":0},{"spese":"0","color":"#949BAA","peso":"0",' +
    '"nome":"Obbligazionario","valore":0},{"spese":"0","color":"#D58629","peso":"0","nome":"Credito",' +
    '"valore":0},{"spese":"0","color":"#BB2E2F","peso":"0","nome":"Azionario","valore":0},' +
    '{"spese":"0","color":"#4C273F","peso":"0","nome":"Investimenti Alternativi","valore":0}],' +
    '"valore":"0","peso":"0%","spese":"0"}';

export default {
    props: {
        tmw: false,
    },
    filters: {
        formatDate: function(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        currency: function(value) {
            value = value != null ? value.toString().replace('.', ',') : '-';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '€';
        },
        currency_price: function(value){
          var symbol = '€';
          if(store.usd){
              symbol = '$';
          }
          value = value != null ? value.toString().replace('.', ',') : '-';
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + symbol;
        },
        percentage: function(value) {
            if (value) {
                value = value.toString().replace('.', ',');
                return value + '%';
            }
            return value;
        },
        clean: function(value) {
            if (value) {
                value = value.toString().replace('.', ',');
                return value;
            }
            return value;
        },
    },
    data() {
        return {
            type: null,
            url: null,
            rows: [],
            loading: true,
            lastLine: null,
            performanceInterval: null,
            horizont: 'ytd',
            contract: null,
        };
    },
    methods: {
        dullAssetClassColor: function(value) {
            if (value) {
                return color(value).fade(0.3);
            }
        },
        dullInstrumentColor: function(value) {
            if (value) {
                return color(value).fade(0.7);
            }
        },
        trendColorNegative: function(rendimento) {
            if (rendimento < 0) {
                var pesoBilanciato = Math.abs(rendimento) * 100;
                var value = pesoBilanciato / this.performanceInterval;
                return value + '%';
            }
        },
        trendColorPositive: function(rendimento) {
            if (rendimento > 0) {
                var pesoBilanciato = Math.abs(rendimento) * 100;
                var value = pesoBilanciato / this.performanceInterval;
                return value + '%';
            }
        },
        toggleAsset(asset) {
            // TODO refactoring
            var tempRows = this.rows.slice();
            tempRows.forEach(function(item) {
                if (item.nome === asset.nome) {
                    item.isOpen = !asset.isOpen;
                }
            });
            this.rows = tempRows;
        },
        toggleAllAssets() {
            var that = this;
            this.rows.forEach(function(item) {
                // console.log(item.nome);
                that.toggleAsset(item);
            });
        },
        toggleSubasset(asset, subasset) {
            // TODO refactoring
            var tempRows = this.rows.slice();
            tempRows.forEach(function(item) {
                if (item.nome === asset.nome) {
                    item.subassets.forEach(function(itemb) {
                        if (itemb.nome === subasset.nome) {
                            itemb.isOpen = !subasset.isOpen;
                        }
                    });
                }
            });
            this.rows = tempRows;
        },
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    var results = response.data.results;
                    if (results.interval != null) {
                        var interval = Math.max(
                            Math.abs(results.interval.min),
                            Math.abs(results.interval.max)
                        );
                        this.performanceInterval = interval;
                    }
                    this.rows = results.assets;
                    this.rows.forEach(asset => {
                        asset.isOpen = false;
                        var subassets = asset.subassets;
                        subassets.forEach(subasset => {
                            subasset.isOpen = false;
                            if (subasset.nome.indexOf('all\\') !== -1) {
                                subasset.nome = subasset.nome.replace(
                                    'all\\',
                                    'a '
                                );
                            }
                        });
                    });
                    this.lastLine = {};
                    this.lastLine = results;
                    delete this.lastLine.assets;
                    if ('liquidita' in results) {
                        this.rows.push(results.liquidita);
                    }
                    if (this.type === 'TREND') {
                        this.$parent.setRefDate(this.lastLine.ref_date);
                        this.$parent.setHorizon(this.horizont);
                        this.$parent.setRendimento(this.lastLine.rendimento);
                    }

                    // this.toggleAllAssets();

                    this.loading = false;
                })
                .catch(e => {
                    console.log(e);
                    this.fetchFakeData();
                    this.$parent.$emit('status_monitor', 'DATA_UPDATING');
                });
        },
        fetchFakeData() {
            var results = JSON.parse(FAKE_DATA);
            this.rows = results.assets;
            this.lastLine = {};
            this.lastLine = results;
            delete this.lastLine.assets;
            if ('liquidita' in results) {
                this.rows.push(results.liquidita);
            }

            this.loading = false;
        },
    },
    computed: {
        selectedTime() {
            return store.getters.getSelectedTime;
        },
        getCurrentContract() {
            return store.getters.getCurrentContract;
        },
        lastRowsIsLiquidità: function(asset) {
            var theClass = 'classB';
            if (asset === false) {
                theClass = 'classA';
            }
            return theClass;
        },
    },
    watch: {
        tmw: function(val) {
            if (val) {
                this.url = this.url.replace('performance', 'performance_tmw');
            } else {
                this.url = this.url.replace('performance_tmw', 'performance');
            }
            this.fetchData();
        },
        selectedTime: function(val) {
            this.horizont = val;
            this.url = BASE_URL + store.mandato;
            if (this.horizont === 'inception') {
                this.url += '/horizont/inception/performance';
            } else {
                this.url +=
                    '/horizont/' + this.horizont.toUpperCase() + '/performance';
            }
            if (this.tmw) {
                this.url = this.url.replace('performance', 'performance_tmw');
            }
            this.fetchData();
        },
        getCurrentContract: function(val) {
            this.contract = val;
            this.url = BASE_URL + this.contract.cod;
            this.type =
                router.currentRoute.name === 'risk'
                    ? 'RISK'
                    : router.currentRoute.name === 'trend'
                    ? 'TREND'
                    : 'COMPOSITION';
            this.url +=
                this.type === 'RISK'
                    ? RISK_URL
                    : this.type === 'TREND'
                    ? PERFORMANCE_URL
                    : COMPOSITION_URL;
            if (this.tmw) {
                this.url = this.url.replace('performance', 'performance_tmw');
            }
            if (this.contract.status !== 'INACTIVE') {
                this.fetchData();
            } else {
                this.fetchFakeData();
            }
        },
        assetOpen: function() {
            this.rows = this.assetOpen;
        },
    },
    created() {
        this.url = BASE_URL + store.mandato;
        this.type =
            router.currentRoute.name === 'risk'
                ? 'RISK'
                : router.currentRoute.name === 'trend'
                ? 'TREND'
                : 'COMPOSITION';
        this.url +=
            this.type === 'RISK'
                ? RISK_URL
                : this.type === 'TREND'
                ? PERFORMANCE_URL
                : COMPOSITION_URL;
        if (this.tmw) {
            this.url = this.url.replace('performance', 'performance_tmw');
        }
        if (store.contract_status !== 'INACTIVE') {
            this.fetchData();
        } else {
            this.fetchFakeData();
        }
    },
};
