import BenchmarkLineChart from '../BenchmarkLineChart/BenchmarkLineChart.vue';
import i18n from 'i18next';
import Card from '../Card/Card.vue';

export default {
    props: {
        trend: null,
        benchmark_selected: false,
    },
    data() {
        return {
            selectedTime: '3year',
            points: [],
        };
    },
    methods: {
        setTime: function(choice, event) {
            this.selectedTime = choice;
        },
        buildPoints: function(trends, benchmarks) {
            var points = [];
            for (var i = 0; i < trends.length; i++) {
                points.push({
                    date: trends[i].date,
                    value: trends[i].value,
                    benchmark: benchmarks[i].value,
                });
            }
            return points;
        },
    },
    computed: {
        title() {
            return i18n.t('Components.Portfolio.TrendChart.title');
        },
        timeswitch_label() {
            return {
                first: i18n.t('Components.Portfolio.TrendChart.first'),
                second: i18n.t('Components.Portfolio.TrendChart.second'),
                third: i18n.t('Components.Portfolio.TrendChart.third'),
                fourth: i18n.t('Components.Portfolio.TrendChart.fourth'),
            };
        },
    },
    watch: {
        selectedTime: function(newValue, oldValue) {
            if (this.trend) {
                this.points = this.buildPoints(
                    this.trend[this.selectedTime],
                    this.trend['benchmark_' + this.selectedTime]
                );
                this.$parent.$emit('selectedTime', newValue);
            }
        },
    },
    created() {
        if (this.trend) {
            this.points = this.buildPoints(
                this.trend[this.selectedTime],
                this.trend['benchmark_' + this.selectedTime]
            );
        }
    },
    components: {
        BenchmarkLineChart,
        Card,
    },
};
