import '../../services/i18n/i18n';
// eslint-disable-next-line no-unused-vars
import AmCharts from 'amcharts3';
// eslint-disable-next-line no-unused-vars
import AmSerial from 'amcharts3/amcharts/serial';

window.AmCharts.monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
];

window.AmCharts.shortMonthNames = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
];

export default {
    props: {
        points: null,
    },
    data() {
        return {};
    },
    methods: {
        drawLineChart() {
            if (this.chart != null) {
                this.chart.dataProvider = this.points;
                this.chart.validateData();
                this.chart.animateAgain();
            } else {
                this.$nextTick(function() {
                    this.chart = window.AmCharts.makeChart('baselinechart', {
                        pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
                        path: '/static/fe/amcharts',
                        hideCredits: true,
                        fontFamily: 'Inter Ui, sans-serif',
                        type: 'serial',
                        theme: 'light',
                        startDuration: 0.35,
                        startEffect: 'easeOutSine',
                        marginRight: 20,
                        marginLeft: 0,
                        autoMarginOffset: 0,
                        fontSize: 14,
                        mouseWheelZoomEnabled: true,
                        dataDateFormat: 'YYYY-MM-DD',
                        valueAxes: [
                            {
                                id: 'v1',
                                axisAlpha: 0,
                                position: 'left',
                                ignoreAxisWidth: false,
                                dashLength: 3,
                                labelFunction: function(value) {
                                    return (
                                        window.AmCharts.formatNumber(
                                            value,
                                            {
                                                precision: 2,
                                                decimalSeparator: ',',
                                                thousandsSeparator: '.',
                                            },
                                            2
                                        ) + '%'
                                    );
                                },
                            },
                        ],
                        balloon: {
                            borderThickness: 1,
                            shadowAlpha: 0,
                        },
                        graphs: [
                            {
                                id: 'g1',
                                type: 'smoothedLine',
                                balloon: {
                                    adjustBorderColor: true,
                                    borderAlpha: 1,
                                    borderThickness: 1.5,
                                    borderColor: '#ffffff',
                                    color: '#1d5b8f',
                                    cornerRadius: 1,
                                    fillColor: '#ffffff',
                                    horizontalPadding: 10,
                                    showBullet: false,
                                    verticalPadding: 5,
                                },
                                bullet: 'none',
                                bulletBorderAlpha: 1,
                                bulletColor: '#FFFFFF',
                                bulletSize: 4,
                                hideBulletsCount: this.points.length,
                                lineThickness: 1,
                                fillAlphas: 0.3,
                                title: 'red line',
                                useLineColorForBulletBorder: true,
                                valueField: 'value',
                                color: '#1d5b8f',
                                lineColor: '#1d5b8f',
                                balloonText:
                                    "<span style='font-size:14px;color:black;'>[[date]]</span><br><span style='font-size:24px;'>[[value]]</span>",
                                balloonFunction: function(item, graph) {
                                    var result = graph.balloonText;
                                    for (var key in item.dataContext) {
                                        var res = item.dataContext[key];
                                        if (key === 'date') {
                                            res = window.AmCharts.formatDate(
                                                new Date(res),
                                                'DD MMMM YYYY'
                                            );
                                        } else {
                                            res = res + '%';
                                        }
                                        result = result.replace(
                                            '[[' + key + ']]',
                                            res
                                        );
                                        result = result.replace('.', ',');
                                    }
                                    return result;
                                },
                            },
                        ],
                        chartScrollbar: {
                            graph: 'g1',
                            oppositeAxis: false,
                            offset: 30,
                            scrollbarHeight: 80,
                            backgroundAlpha: 0,
                            selectedBackgroundAlpha: 0.1,
                            selectedBackgroundColor: '#888888',
                            graphFillAlpha: 0,
                            graphLineAlpha: 0.5,
                            selectedGraphFillAlpha: 0,
                            selectedGraphLineAlpha: 1,
                            autoGridCount: true,
                            color: '#AAAAAA',
                        },
                        chartCursor: {
                            pan: false,
                            cursorAlpha: 1,
                            cursorColor: '#258cbb',
                            limitToGraph: 'g1',
                            valueLineAlpha: 0.2,
                        },
                        categoryField: 'date',
                        categoryAxis: {
                            parseDates: true,
                            dashLength: 1,
                            minorGridEnabled: true,
                            gridAlpha: 0,
                            gridThickness: 0,
                        },
                        export: {
                            enabled: true,
                        },
                        dataProvider: this.points,
                    });
                    this.chart.invalidateSize();
                });
            }
        },
    },
    computed: {},
    watch: {
        // eslint-disable-next-line no-unused-vars
        points: function(newValue, oldValue) {
            if (this.chart != null) {
                this.chart.dataProvider = this.points;
                this.chart.validateData();
                this.chart.animateAgain();
            }
        },
    },
    created() {
        if (this.points != undefined && this.points.length > 0) {
            this.drawLineChart();
        }
    },
};
