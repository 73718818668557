import i18n from 'i18next';
import '../../services/i18n/i18n';
import store from '../../store/privateArea';
import axios from 'axios';
import $ from 'jquery';

const BASE_URL = '/wm/api/v1/mandato/';
const CAPITAL_URL = '/capital/monthly';

window.AmCharts.monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
];

window.AmCharts.shortMonthNames = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
];

var balloonText =
    "<span style='font-size:14px;color:black;float:none;'>[[category]]</span>" +
    "<br /><span style='font-size:24px;'>[[value]]</span>[[movements]]";

function getMovements(movements) {
    var totConferimenti = 0;
    var totPrelievi = 0;
    var result = '<table class="table">';
    movements.forEach(function(item) {
        if (item.status === 'SENT') {
            if (item.operation === 'CON') {
                totConferimenti += Number(item.amount);
            } else {
                totPrelievi += Number(item.amount);
            }
        }
    });
    if (totConferimenti > 0) {
        result += '<tr><td>Tot. Conferimenti</td>';
        result +=
            '<td>' +
            window.AmCharts.formatNumber(
                totConferimenti,
                {
                    precision: 0,
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                },
                0
            ) +
            ' € </td>';
        result += '</tr>';
    }
    if (totPrelievi > 0) {
        result += '<tr><td>Tot. Prelievi</td>';
        result +=
            '<td>' +
            window.AmCharts.formatNumber(
                totPrelievi,
                {
                    precision: 0,
                    decimalSeparator: ',',
                    thousandsSeparator: '.',
                },
                0
            ) +
            ' € </td>';
        result += '</tr>';
    }

    return result + '</table>';
}

export default {
    data() {
        return {
            url: null,
            loading: true,
            points: [],
            chart: null,
            reanimated: false,
            lastScrollTop: 0,
        };
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        reanimate(event) {
            var st = $(window).scrollTop();
            if (
                st > this.lastScrollTop &&
                !this.reanimated &&
                this.chart != null &&
                $('.Deposit__title').is(':visible')
            ) {
                this.chart.validateData();
                this.chart.animateAgain();
                this.reanimated = true;
                this.lastScrollTop = Number.MAX_SAFE_INTEGER;
            }
            if (st < 500) {
                var that = this;
                setTimeout(function() {
                    that.reanimated = false;
                    that.lastScrollTop = st + 100;
                }, 500);
            }
        },
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.points = response.data.results;
                    this.points.forEach(function(index) {
                        if ('movements' in index) {
                            index.bullet = 'circle';
                        }
                    });
                    this.loading = false;
                    this.drawBarChart();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        drawBarChart() {
            if (this.chart != null) {
                this.chart.dataProvider = this.points;
                this.chart.validateData();
                this.chart.animateAgain();
            } else {
                this.chart = window.AmCharts.makeChart('barchartdiv', {
                    hideCredits: true,
                    marginRight: 0,
                    marginLeft: 0,
                    autoMarginOffset: 0,
                    type: 'serial',
                    theme: 'light',
                    // fontFamily: 'Inter Ui',
                    fontSize: 12,
                    dataProvider: this.points,
                    gridAboveGraphs: true,
                    startDuration: 1,
                    responsive: {
                        enabled: true,
                        overrides: {
                            legend: {
                                enabled: false,
                            },
                            valueAxes: {
                                inside: true,
                            },
                            categoryAxes: {
                                labelsEnabled: false,
                            },
                        },
                    },
                    valueAxes: [
                        {
                            id: 'v2',
                            axisAlpha: 0,
                            position: 'left',
                            ignoreAxisWidth: false,
                            dashLength: 3,
                            unit: '€',
                            unitPosition: 'right',
                            labelFunction: function(value) {
                                return (
                                    window.AmCharts.formatNumber(
                                        value,
                                        {
                                            precision: 0,
                                            decimalSeparator: ',',
                                            thousandsSeparator: '.',
                                        },
                                        0
                                    ) + ' €'
                                );
                            },
                        },
                    ],
                    balloon: {
                        adjustBorderColor: true,
                        borderAlpha: 1,
                        borderThickness: 1.5,
                        borderColor: '#ffffff',
                        color: '#1d5b8f',
                        cornerRadius: 1,
                        fillColor: '#ffffff',
                        horizontalPadding: 10,
                        showBullet: false,
                        verticalPadding: 5,
                    },
                    graphs: [
                        {
                            fillAlphas: 1,
                            lineAlpha: 1,
                            type: 'column',
                            valueField: 'value',
                            lineColor: '#1d5b8f',
                            bulletColor: '#ff2350',
                            bulletAlpha: 1,
                            bulletOffset: 15,
                            bullet: 'custom',
                            bulletField: 'bullet',
                            balloonText: balloonText,
                            balloonFunction: function(item, graph) {
                                var result = graph.balloonText;
                                for (var key in item.dataContext) {
                                    var res = item.dataContext[key];
                                    if (key === 'category') {
                                        res = window.AmCharts.formatDate(
                                            new Date(item.dataContext.ref_date),
                                            'MMMM YYYY'
                                        );
                                    } else if (key === 'movements') {
                                        if ('movements' in item.dataContext) {
                                            var movements = getMovements(
                                                item.dataContext.movements
                                            );
                                            res = movements;
                                        } else {
                                            res = '';
                                        }
                                    } else {
                                        res =
                                            window.AmCharts.formatNumber(
                                                res,
                                                {
                                                    precision: 2,
                                                    decimalSeparator: ',',
                                                    thousandsSeparator: '.',
                                                },
                                                2
                                            ) + ' €';
                                    }
                                    result = result.replace(
                                        '[[' + key + ']]',
                                        res
                                    );
                                }
                                return result;
                            },
                        },
                    ],
                    chartCursor: {
                        categoryBalloonEnabled: false,
                        cursorAlpha: 0,
                        zoomable: false,
                    },
                    categoryField: 'category',
                    categoryAxis: {
                        autoWrap: true,

                        gridPosition: 'start',
                        gridAlpha: 0,
                        tickPosition: 'start',
                        tickLength: 20,
                        dashLength: 3,
                    },
                    export: {
                        enabled: true,
                    },
                });
            }
        },
    },
    computed: {
        getCurrentContract() {
            return store.getters.getCurrentContract;
        },
        title() {
            return i18n.t('Components.Deposit.title');
        },
    },
    watch: {
        getCurrentContract: function(val) {
            this.contract = val;
            this.url = BASE_URL + this.contract.cod;
            this.url += CAPITAL_URL;
            this.fetchData();
        },
    },
    created() {
        this.url = BASE_URL + store.mandato;
        this.url += CAPITAL_URL;
        this.fetchData();
        window.addEventListener('scroll', this.reanimate);
    },
    destroyed() {
        window.removeEventListener('scroll', this.reanimate);
    },
};
