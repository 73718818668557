import '../../services/i18n/i18n';
import AmCharts from 'amcharts3';
import AmSerial from 'amcharts3/amcharts/serial';

window.AmCharts.monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
];

window.AmCharts.shortMonthNames = [
    'Gen',
    'Feb',
    'Mar',
    'Apr',
    'Mag',
    'Giu',
    'Lug',
    'Ago',
    'Set',
    'Ott',
    'Nov',
    'Dic',
];

export default {
    props: {
        points: null,
        benchmark_selected: false,
    },
    data() {
        return {
            benchmark_switch: true,
            chart: null,
        };
    },
    methods: {
        drawLineChart() {
            if (this.chart != null) {
                this.chart.dataProvider = this.points;
                this.chart.validateData();
                this.chart.animateAgain();
            } else {
                this.$nextTick(function() {
                    // todo: check this ID #benchmarklinechart
                    this.chart = window.AmCharts.makeChart(
                        'benchmarklinechart',
                        {
                            pathToImages:
                                'https://cdn.amcharts.com/lib/3/images/',
                            path: '/static/fe/amcharts',
                            hideCredits: true,
                            fontFamily: 'Inter Ui, sans-serif',
                            type: 'serial',
                            theme: 'light',
                            startDuration: 0.35,
                            startEffect: 'easeOutSine',
                            marginRight: 20,
                            marginLeft: 0,
                            autoMarginOffset: 0,
                            // fontFamily: 'Inter Ui',
                            fontSize: 14,
                            mouseWheelZoomEnabled: true,
                            dataDateFormat: 'YYYY-MM-DD',
                            valueAxes: [
                                {
                                    id: 'v1',
                                    axisAlpha: 0,
                                    position: 'left',
                                    ignoreAxisWidth: false,
                                    dashLength: 3,
                                    labelFunction: function(value) {
                                        return (
                                            window.AmCharts.formatNumber(
                                                value,
                                                {
                                                    precision: 2,
                                                    decimalSeparator: ',',
                                                    thousandsSeparator: '.',
                                                },
                                                2
                                            ) + '%'
                                        );
                                    },
                                },
                            ],
                            balloon: {
                                borderThickness: 1,
                                shadowAlpha: 0,
                            },
                            graphs: [
                                {
                                    id: 'g1',
                                    balloon: {
                                        adjustBorderColor: true,
                                        borderAlpha: 1,
                                        borderThickness: 1.5,
                                        borderColor: '#ffffff',
                                        color: '#1d5b8f',
                                        cornerRadius: 1,
                                        fillColor: '#ffffff',
                                        horizontalPadding: 10,
                                        showBullet: false,
                                        verticalPadding: 5,
                                    },
                                    bullet: 'round',
                                    bulletBorderAlpha: 1,
                                    bulletColor: '#FFFFFF',
                                    bulletSize: 4,
                                    hideBulletsCount: 100,
                                    lineThickness: 2,
                                    title: 'red line',
                                    useLineColorForBulletBorder: true,
                                    valueField: 'value',
                                    color: '#1d5b8f',
                                    lineColor: '#1d5b8f',
                                    balloonText:
                                        " <span style='font-size:18px;color:black;'>[[date]]</span><br> <table> <thead> <tr> <th style=\"font-weight:100;font-size:14px;color:grey;\">PORTAFOGLIO</th> <th style=\"font-weight:100;font-size:14px;color:grey;padding-left:15px;\">BENCHMARK</th> </tr> </thead> <tbody> <tr> <td style='font-weight:100;font-size:24px;'>[[value]]</td><td style='font-weight:100;font-size:24px;color:grey;padding-left:15px;'>[[benchmark]]</td> </tr> </body> <table>",
                                    balloonFunction: function(item, graph) {
                                        var result = graph.balloonText;
                                        for (var key in item.dataContext) {
                                            var res = item.dataContext[key];
                                            if (key === 'date') {
                                                res = window.AmCharts.formatDate(
                                                    new Date(res),
                                                    'DD MMMM YYYY'
                                                );
                                            } else {
                                                res = res + '%';
                                            }
                                            result = result.replace(
                                                '[[' + key + ']]',
                                                res
                                            );
                                            result = result.replace('.', ',');
                                        }
                                        return result;
                                    },
                                    type: 'smoothedLine',
                                },
                                {
                                    id: 'g2',
                                    bullet: 'round',
                                    hideBulletsCount: 100,
                                    lineThickness: 2,
                                    showBalloon: false,
                                    title: 'red line',
                                    useLineColorForBulletBorder: true,
                                    valueField: 'benchmark',
                                    color: 'grey',
                                    lineColor: 'grey',
                                    type: 'smoothedLine',
                                },
                            ],
                            chartScrollbar: {
                                graph: 'g1',
                                oppositeAxis: false,
                                offset: 30,
                                scrollbarHeight: 80,
                                backgroundAlpha: 0,
                                selectedBackgroundAlpha: 0.1,
                                selectedBackgroundColor: '#888888',
                                graphFillAlpha: 0,
                                graphLineAlpha: 0.5,
                                selectedGraphFillAlpha: 0,
                                selectedGraphLineAlpha: 1,
                                autoGridCount: true,
                                color: '#AAAAAA',
                            },
                            chartCursor: {
                                pan: true,
                                valueLineEnabled: true,
                                valueLineBalloonEnabled: true,
                                cursorAlpha: 1,
                                cursorColor: '#258cbb',
                                limitToGraph: 'g1',
                                valueLineAlpha: 0.2,
                                valueZoomable: true,
                            },
                            categoryField: 'date',
                            categoryAxis: {
                                parseDates: true,
                                dashLength: 1,
                                minorGridEnabled: true,
                                gridAlpha: 0,
                                gridThickness: 0,
                            },
                            export: {
                                enabled: true,
                            },
                            dataProvider: this.points,
                        }
                    );
                    this.chart.invalidateSize();
                    if (this.benchmark_selected) {
                        this.chart.showGraph(this.chart.graphs[1]);
                    } else {
                        this.chart.hideGraph(this.chart.graphs[1]);
                    }
                });
            }
        },
    },
    computed: {},
    watch: {
        points: function(newValue, oldValue) {
            if (this.chart != null) {
                this.chart.dataProvider = this.points;
                this.chart.validateData();
                this.chart.animateAgain();
            }
        },
        benchmark_selected: function(newValue, oldValue) {
            if (newValue) {
                this.chart.showGraph(this.chart.graphs[1]);
            } else {
                this.chart.hideGraph(this.chart.graphs[1]);
            }
        },
    },
    created() {
        if (this.points != undefined && this.points.length > 0) {
            this.drawLineChart();
        }
    },
};
