import i18n from 'i18next';
import Card from '../Card/Card.vue';
import BaseTable from '../BaseTable/BaseTable.vue';

export default {
    props: {
        risk: null,
        lastline: null,
    },
    data() {
        return {
            table_columns: [
                { name: 'name', type: 'simple', title: 'Strumenti' },
                { name: '', type: 'simpe', title: '' },
                { name: 'portfolio', type: 'bar', title: 'Contributo' },
                { name: 'benchmark', type: 'simple', title: 'Benchmark' },
                {
                    name: 'volatility_portfolio',
                    type: 'simple',
                    title: 'Volatilità',
                },
                // {'name':'volatility_benchmark','type':'simple','title':'Volatilità Benchmark'}
            ],
            tot_row: [],
            table_rows: null,
        };
    },
    computed: {
        title() {
            return i18n.t('Components.Portfolio.RiskTable.title');
        },
    },
    created() {
        this.table_rows = this.risk;
        this.tot_row = this.lastline;
    },
    components: {
        Card,
        BaseTable,
    },
};
