import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    actions: {},
    data: {
        mandato: null,
        csrfToken: null,
        consultant: null,
        wm: false,
    },
    getters: {},
    mutations: {},
    setters: {},
    state: {},
});
