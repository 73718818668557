import Card from '../Card/Card.vue';
import axios from 'axios';
import store from '../../store/whisperer.js';

const BASE_URL = '/a/api/v1/investment/';
const OWNER_BASE_URL = '/a/api/v1/personal_investment/';
const DATA_URL = '/info/';

export default {
    data() {
        return {
            url: null,
            mandato: null,
            customer_details: null,
            owner: false,
        };
    },
    methods: {
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    var result = response.data.results;
                    if (!isNaN(result.condizioni_gestione)) {
                        result.condizioni_gestione =
                            String(result.condizioni_gestione).replace(
                                '.',
                                ','
                            ) + '%';
                    }
                    this.customer_details = result;
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    created() {
        this.mandato = store.mandato;
        this.owner = document.getElementById('owner').value;
        if (this.owner) {
            this.url = OWNER_BASE_URL + store.mandato + DATA_URL;
        } else {
            this.url = BASE_URL + store.mandato + DATA_URL;
        }
        this.fetchData();
    },
    components: {
        Card,
    },
};
