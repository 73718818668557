import router from '../../router/onboarding/router';
import store from '../../store/onboarding';
import i18n from 'i18next';

export default {
    destroyed() {
        sessionStorage.setItem(
            'breadcrumb',
            JSON.stringify({ cosigner: store.state.current_cosigner, step: 2 })
        );
    },
    data() {
        return {
            wm: false,
            existing_customer: false
        };
    },
    created() {
        store.dispatch('get_districts');
        store.dispatch('get_docs');
        store.dispatch('get_time');
        if (this.$store.state.wm) {
            this.wm = true;
        }
         if (this.$store.state.existing_customer) {
            this.existing_customer = true;
            sessionStorage.setItem(
                'breadcrumb',
                JSON.stringify({ cosigner: store.state.current_cosigner, step: 2 })
            );
            this.start_compilation();
        }
    },
    computed: {
        title() {
            return i18n.t('Components.PrepareDocuments.title');
        },
        subTitle() {
            if (store.state.total_cosigners === 1) {
                return i18n.t('Components.PrepareDocuments.subTitle');
            }
            return store.state.with_beneficiary
                ? i18n.t('Components.PrepareDocuments.subTitle_Beneficiary')
                : i18n.t('Components.PrepareDocuments.subTitle_Multi');
        },
        identityDocument() {
            return i18n.t('Components.PrepareDocuments.identityDocument');
        },
        fiscalDocument() {
            return i18n.t('Components.PrepareDocuments.fiscalDocument');
        },
    },
    methods: {
        start_compilation() {
            return store.dispatch('start_compilation').then(() => {
                router.push({ name: 'informazioni-personali' });
            });
        },
        nextForm() {
            store.dispatch('nextForm');
        },
    },
    name: 'prepare-documents',
    template: '</prepare-documents>',
};
