import '../../services/i18n/i18n';
// eslint-disable-next-line no-unused-vars
import router from '../../router/privateArea/router';
import store from '../../store/privateArea';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import Amcharts from 'amcharts3';
// eslint-disable-next-line no-unused-vars
import AmSerial from 'amcharts3/amcharts/serial';
// eslint-disable-next-line no-unused-vars
import Ammap from 'ammap3/ammap/ammap';
// eslint-disable-next-line no-unused-vars
import AmPie from 'amcharts3/amcharts/pie';

import 'ammap3/ammap/maps/js/worldLow';

const BASE_URL = '/wm/api/v1/mandato/';
const ALLOCATION_URL = '/asset/geoallocation/';

export default {
    data() {
        return {
            url: null,
            loading: true,
            distribution: null,
            points: [],
            chart: null,
            stock_selected: true,
        };
    },
    methods: {
        getCountry(key) {
            if (key == 'MEX') {
                return 'MX';
            }
            if (key == 'POL') {
                return 'PL';
            }
            if (key == 'KOR') {
                return 'KR';
            }
            if (key == 'IRL') {
                return 'IE';
            }
            if (key == 'CHN') {
                return 'CN';
            }
            if (key == 'SWE') {
                return 'SE';
            }
            if (key == 'SEN') {
                return 'SN';
            }
            if (key == 'ISL') {
                return 'IS';
            }
            if (key == 'ISR') {
                return 'IL';
            }
            if(key=='ARE'){
                return 'AE'
            }
            if(key == 'ARG'){
                return 'AG'
            }
            if(key == 'ESP'){
                return 'ES'
            }
            if(key == 'EST'){
                return 'EE'
            }
            if(key == 'CHL'){
                return 'CL'
            }
            if(key == 'CHE'){
                return 'CH'
            }
            if(key == 'PAK'){
                return 'PK'
            }
            if(key == 'PAN'){
                return 'PA'
            }
            if(key == 'AUS'){
                return 'AU'
            }
            if(key == 'AUT'){
                return 'AT'
            }
            return key.substr(0, 2);
        },
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.distribution = response.data.results;
                    this.loading = false;
                    this.drawGeoChart(this.stock_selected);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        buildPoints(val) {
            var points = [];
            var max = 0;
            for (var key in this.distribution) {
                var point = {
                    id: this.getCountry(key),
                    color: val ? '#bb2e2f' : '#949baa',
                    balloonText: '[[title]]\n[[value]]%',
                };
                var stats = this.distribution[key];
                var include = false;

                for (var s in stats) {
                    if (stats[s].type == 'Azionario' && this.stock_selected) {
                        point.value = stats[s].value;
                        point.title = stats[s].ita_name;
                        include = true;
                        if (parseFloat(point.value) > max) {
                            max = parseFloat(point.value);
                        }
                    }
                    if (
                        stats[s].type == 'Obbligazionario' &&
                        !this.stock_selected
                    ) {
                        point.value = stats[s].value;
                        point.title = stats[s].ita_name;
                        include = true;
                        if (parseFloat(point.value) > max) {
                            max = parseFloat(point.value);
                        }
                    }
                }
                if (include) {
                    points.push(point);
                }
            }

            var high = (2 * max) / 3;
            var middle = max / 2;
            var low = max / 3;

            for (var p in points) {
                if (parseFloat(points[p].value) > high) {
                    points[p].alpha = 1;
                } else if (parseFloat(points[p].value) >= middle) {
                    points[p].alpha = 0.75;
                } else if (parseFloat(points[p].value) >= low) {
                    points[p].alpha = 0.5;
                } else {
                    points[p].alpha = 0.25;
                }
            }

            return points;
        },
        drawGeoChart(val) {
            this.points = this.buildPoints(val);
            if (this.chart != null) {
                this.chart.dataProvider.areas = this.points;
                this.chart.validateData();
                this.chart.invalidateSize();
            } else {
                this.chart = window.AmCharts.makeChart('geochart', {
                    type: 'map',
                    theme: 'light',
                    projection: 'miller',
                    mouseWheelZoomEnabled: false,
                    zoomOnDoubleClick: false,
                    fontSize: 14,
                    fontFamily: 'Inter Ui, sans-serif',
                    preventDragOut: true,
                    autoDisplay: true,
                    autoTransform: true,
                    zoomControl: {
                        zoomControlEnabled: true,
                        panControlEnabled: false,
                        homeButtonEnabled: false,
                    },
                    dataProvider: {
                        map: 'worldLow',
                        areas: this.points,
                    },
                    areasSettings: {
                        color: val ? '#bb2e2f' : '#949baa',
                        colorSolid: val ? '#bb2e2f' : '#949baa',
                        autoZoom: true,
                        selectedColor: val ? '#bb2e2f' : '#949baa',
                    },
                    export: {
                        enabled: false,
                    },
                    hideCredits: true,
                    pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
                });
                this.chart.invalidateSize();
            }
        },
    },
    computed: {
        getCurrentContract() {
            return store.getters.getCurrentContract;
        },
    },
    watch: {
        getCurrentContract: function(val) {
            this.contract = val;
            this.url = BASE_URL + this.contract.cod;
            this.url += ALLOCATION_URL;
        },
        stock_selected: function(val) {
            this.drawGeoChart(val);
            var that = this;
            setTimeout(function() {
                that.drawGeoChart(val);
            }, 500);
        },
    },
    created() {
        if (store.mandato != null && store.contract_status != null) {
            this.stock_selected = true;
            this.url = BASE_URL + store.mandato;
            this.url += ALLOCATION_URL;
            if (store.contract_status !== 'INACTIVE') {
                this.fetchData();
            }
        }
    },
    updated() {
        $('[data-toggle="popover"]').popover({ html: true, trigger: 'hover' });
    },
};
