import Card from '../Card/Card.vue';
import { toPercent, toCurrencySimple } from '../../services/filters';

export default {
    props: {
        label: '',
        value: 0,
        format: '',
        tooltip: null,
    },
    computed: {
        isPercent() {
            return this.format === 'percent';
        },
        isCents() {
            return this.format === 'cents';
        },
        isValid() {
            return this.value != 'N/A';
        },
        isJustPercentNoSign() {
            return this.format === 'just_percent_no_sign';
        },
        isJustPercent() {
            return this.format === 'just_percent';
        },
        isMoney() {
            return this.format === 'money';
        },
    },
    created() {
        setTimeout(function() {
            $('[data-toggle="popover"]').popover({
                html: true,
                trigger: 'hover',
            });
        }, 1000);
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        value: function(newValue, oldValue) {
            console.log(newValue);
        },
    },
    components: {
        Card,
    },
    filters: {
        toPercent,
        toCurrencySimple,
    },
};
