import Spinner from '../Spinner/Spinner.vue';

// function noop() {}

export default {
    props: {
        label: '',
        color: '',
        onClick: {},
        disabled: false,
        showLoading: false,
    },
    methods: {
        click() {
            if (!this.disabled && !this.showLoading) {
                this.onClick();
            } else {
                /* do nothing */
            }
        },
    },
    computed: {
        spinnerColor() {
            switch (this.color) {
                case 'white':
                    return 'cobalt';
                default:
                    return 'white';
            }
        },
    },
    components: {
        Spinner,
    },
};
