import router from '../../router/onboarding/router';
import store from '../../store/onboarding';
import i18n from 'i18next';

export default {
    beforeCreate() {
        if (this.$store.state.wm) {
            store
                .dispatch('set_total_cosigners', {
                    total_cosigners: 1,
                    with_beneficiary: false,
                })
                .then(() => router.push({ name: 'preparati' }));
        }
    },
    destroyed() {
        sessionStorage.setItem(
            'breadcrumb',
            JSON.stringify({ cosigner: store.state.current_cosigner, step: 1 })
        );
    },
    computed: {
        cosigners() {
            return store.state.cosigners;
        },
        title() {
            return i18n.t('Components.ChooseSigner.title');
        },
        total_cosigners() {
            return store.state.total_cosigners;
        },
    },
    methods: {
        set_total_cosigners(id, withBeneficiary) {
            console.log(id)
            console.log(withBeneficiary)
            store
                .dispatch('set_total_cosigners', {
                    total_cosigners: id,
                    with_beneficiary: withBeneficiary,
                })
                .then(() => router.push({ name: 'preparati' }));
        },
    },
    name: 'choose-signer',
    template: '</choose-signer>',
};
