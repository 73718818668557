// prettier-ignore
/* eslint-disable no-unused-vars */
import { truncateDecimals } from './helpers';
import { formatCurrency } from './helpers';

export function toPercent(value, fixedDecimals, showPositiveSign) {
    return String(
        (showPositiveSign && value > 0 ? '+' : '') +
            truncateDecimals(parseFloat(value), fixedDecimals)
    ).replace('.', ',');
}

export function toCurrencySimple(value) {
    return formatCurrency(value);
}

export function toCurrency(value, fixedDecimals, currency) {
    const fixedCurrency =
        truncateDecimals(parseFloat(value), fixedDecimals) + 'currency';
    return (
        fixedCurrency.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        ',' +
        fixedCurrency.split('.')[1]
    );
}
