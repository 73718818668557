import router from '../../router/onboarding/router';
import store from '../../store/onboarding';

export default {
    data() {
        return {
            wm: false,
        };
    },
    created() {
        if (this.$store.state.wm) {
            this.wm = true;
        }
    },
    methods: {
        reset_total_cosigners() {
            store
                .dispatch('reset_total_cosigners')
                .then(() => router.push({ name: 'anagrafica' }))
                .catch(() => {});
        },
    },
    name: 'reset-cosigner',
    template: '</reset-cosigner>',
};
