export default {
    props: {
        elevation: 0,
    },
    computed: {
        elevationClass() {
            return 'Card--elevation-' + this.elevation;
        },
    },
};
