import Vue from 'vue';
import Router from 'vue-router';
import store from '../../store/onboarding';


import personalInformations from '../../components/PersonalInformations/PersonalInformations.vue';
import addresses from '../../components/Addresses/Addresses.vue';
import contract from '../../components/Contract/Contract.vue';
import documentsInformations from '../../components/DocumentsInformations/DocumentsInformations.vue';
import uploadDocuments from '../../components/UploadDocuments/UploadDocuments.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'anagrafica',
            path: '/onboarding/anagrafica/',
        },
        {
            name: 'preparati',
            path: '/onboarding/anagrafica/preparati',
        },
        {
            component: personalInformations,
            name: 'informazioni-personali',
            path: '/onboarding/anagrafica/informazioni-personali',
            props: {
                nextPath: '/onboarding/anagrafica/indirizzi',
                saveDataUrl: '/onboarding/personalinfo',
            },
        },
        {
            name: 'indirizzi',
            path: '/onboarding/anagrafica/indirizzi',
            component: addresses,
            props: {
                nextPath: '/onboarding/anagrafica/contratti',
                saveDataUrl: '/onboarding/adressinfo',
            },
        },
        {
            name: 'contratto',
            path: '/onboarding/anagrafica/contratti',
            component: contract,
            props: {
                nextPath: '/onboarding/anagrafica/documenti',
                saveDataUrl: '/onboarding/contract',
            },
        },
        {
            name: 'documenti',
            path: '/onboarding/anagrafica/documenti',
            component: documentsInformations,
            props: {
                nextPath: '/onboarding/anagrafica/carica-documenti',
                saveDataUrl: '/onboarding/docinfo',
            },
        },
        {
            name: 'carica-documenti',
            path: '/onboarding/anagrafica/carica-documenti',
            component: uploadDocuments,
            props: {
                saveDataUrl: '/onboarding/docinfo',
            },
        },
        {
            name: 'questionario-aml',
            path: '/onboarding/anagrafica/questionario-aml',
        },
    ],
});
