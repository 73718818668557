import Card from '../Card/Card.vue';
import BaseBarChart from '../BaseBarChart/BaseBarChart.vue';
import BaseTable from '../BaseTable/BaseTable.vue';
import store from '../../store/whisperer.js';
import axios from 'axios/index';
import { formatCurrency } from '../../services/helpers';
import i18n from 'i18next';
import moment from 'moment/moment';

const BASE_URL = '/wm/api/v1/mandato/';
const ALLOCATION_URL = '/asset/allocation/';
const COMPOSITION_URL = '/composition/';

export default {
    data() {
        return {
            bars: null,
            rows: null,
            url_bars: null,
            url_table: null,
            tot_row: null,
            lastLine: null,
            currency: '€',
            table_columns: [
                { name: 'nome', type: 'simple', title: 'Strumenti' },
                { name: '', type: 'simple', title: '' },
                { name: 'peso', type: 'bar', title: 'Peso' },
                { name: 'valore', type: 'simple', title: 'Valore' },
                { name: 'spese', type: 'simple', title: 'Costo' },
            ],
        };
    },
    computed: {
        disclaimer() {
            return i18n.t('General.disclaimer');
        },
    },
    methods: {
        fetchBarData() {
            axios
                .get(this.url_bars, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.bars = response.data.results;
                    this.bars.forEach(function(item) {
                        item.name = item.asset_class;
                    });
                    this.fetchTableData();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        fetchTableData() {
            axios
                .get(this.url_table, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    var results = response.data.results;
                    this.rows = results.assets;
                    this.rows.forEach(asset => {
                        asset.isOpen = false;
                        asset.name = asset.nome;
                        asset.weight = asset.peso;
                        asset.peso = asset.peso.replace('.', ',') + '%';
                        asset.spese = asset.spese.replace('.', ',') + '%';
                        asset.valore = formatCurrency(asset.valore);
                        var subassets = asset.subassets;
                        subassets.forEach(subasset => {
                            subasset.isOpen = false;
                            if (subasset.nome.indexOf('all\\') !== -1) {
                                subasset.nome = subasset.nome.replace(
                                    'all\\',
                                    'a '
                                );
                            }
                            subasset.weight = subasset.peso;
                            subasset.name = subasset.nome;
                            subasset.peso =
                                subasset.peso.replace('.', ',') + '%';
                            subasset.spese =
                                subasset.spese.replace('.', ',') + '%';
                            subasset.valore = formatCurrency(subasset.valore);
                            subasset.instruments.forEach(instrument => {
                                instrument.name = instrument.nome;
                                instrument.peso =
                                    instrument.peso.replace('.', ',') + '%';
                                instrument.spese =
                                    instrument.spese.replace('.', ',') + '%';
                                instrument.valore = formatCurrency(
                                    instrument.valore
                                );
                            });
                        });
                    });
                    this.lastLine = {};
                    this.lastLine = results;
                    delete this.lastLine.assets;
                    if ('liquidita' in results) {
                        results.liquidita.peso =
                            results.liquidita.peso.replace('.', ',') + '%';
                        results.liquidita.valore = formatCurrency(
                            results.liquidita.valore
                        );
                        this.rows.push(results.liquidita);
                    }
                    this.tot_row = [
                        '',
                        '',
                        '',
                        this.lastLine.peso,
                        formatCurrency(this.lastLine.valore),
                        this.lastLine.spese.replace('.', ',') + '%',
                    ];
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    created() {
        this.url_bars = BASE_URL + store.mandato;
        this.url_bars += ALLOCATION_URL;
        this.url_table = BASE_URL + store.mandato;
        this.url_table += COMPOSITION_URL;
        this.fetchBarData();
        if(store.usd){
            this.currency = '$';
        }
    },
    components: {
        Card,
        BaseBarChart,
        BaseTable,
    },
    filters: {
        formatDate: function(date) {
            return moment(date).format('DD-MM-YYYY');
        },
    },
};
