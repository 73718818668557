/* eslint no-console: [0] */
/* eslint-disable no-unused-vars */
import it from './it.json';
import i18n from 'i18next';

i18n.init(
    {
        lng: 'it',
        resources: {
            it: {
                translation: it,
            },
        },
    },
    err => {
        //console.log(err)
    }
);
