import Card from '../Card/Card.vue';
import BaseLineChart from '../BaseLineChart/BaseLineChart.vue';
import BaseTable from '../BaseTable/BaseTable.vue';
import store from '../../store/whisperer.js';
import axios from 'axios/index';
// eslint-disable-next-line no-unused-vars
import { formatCurrency } from '../../services/helpers';

const BASE_URL = '/wm/api/v1/mandato/';
// eslint-disable-next-line no-unused-vars
const PERFORMANCE_URL = '/horizont/inception/performance/';

export default {
    data() {
        return {
            chart_url: null,
            table_url: null,
            points: null,
            rows: null,
            tot_row: null,
            performanceInterval: null,
            table_columns: [
                { name: 'nome', type: 'simple', title: 'Strumenti' },
                { name: '', type: 'simple', title: '' },
                { name: 'rendimento', type: 'simple', title: 'Rendimento' },
                { name: 'weight', type: 'chart', title: '' },
            ],
            selectedTime: 'ytd',
        };
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        selectedTime: function(newValue, oldValue) {
            this.chart_url = BASE_URL + store.mandato;
            this.chart_url += '/horizont/' + newValue + '/returns';
            this.table_url = BASE_URL + store.mandato;
            if (newValue != 'inception') {
                this.table_url +=
                    '/horizont/' + newValue.toUpperCase() + '/performance/';
            } else {
                this.table_url += '/horizont/inception/performance/';
            }
            this.fetchData();
        },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        setTime: function(choice, event) {
            this.selectedTime = choice;
        },
        fetchData() {
            axios
                .get(this.chart_url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.points = response.data.results;
                    this.fetchTableData();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        fetchTableData() {
            axios
                .get(this.table_url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    var results = response.data.results;
                    if (results.interval != null) {
                        var interval = Math.max(
                            Math.abs(results.interval.min),
                            Math.abs(results.interval.max)
                        );
                        this.performanceInterval = interval;
                    }
                    this.rows = results.assets;
                    this.rows.forEach(asset => {
                        asset.isOpen = false;
                        asset.name = asset.nome;
                        asset.weight = parseFloat(asset.rendimento);
                        asset.rendimento =
                            asset.rendimento.replace('.', ',') + '%';

                        var subassets = asset.subassets;
                        subassets.forEach(subasset => {
                            subasset.isOpen = false;
                            if (subasset.nome.indexOf('all\\') !== -1) {
                                subasset.nome = subasset.nome.replace(
                                    'all\\',
                                    'a '
                                );
                            }
                            subasset.weight = parseFloat(subasset.rendimento);
                            subasset.name = subasset.nome;
                            subasset.rendimento =
                                subasset.rendimento.replace('.', ',') + '%';
                            subasset.instruments.forEach(instrument => {
                                instrument.name = instrument.nome;
                                instrument.rendimento =
                                    instrument.rendimento.replace('.', ',') +
                                    '%';
                            });
                        });
                    });
                    this.tot_row = [
                        '',
                        '',
                        '',
                        results.rendimento.replace('.', ',') + '%',
                        '',
                    ];
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    created() {
        this.chart_url = BASE_URL + store.mandato;
        this.table_url = BASE_URL + store.mandato;
        this.chart_url += '/horizont/YTD/returns';
        this.table_url += '/horizont/YTD/performance/';
        this.fetchData();
    },
    components: {
        Card,
        BaseLineChart,
        BaseTable,
    },
};
