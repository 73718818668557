/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router/onboarding/router';
import cookie from 'js-cookie';
import $ from 'jquery';

Vue.use(Vuex);

const BASE_URL = '/onboarding/';

export default new Vuex.Store({
    actions: {
        // Data actions
        set_wm({ commit }, { value }) {
            commit('SET_WM', { value });
        },
        set_existing_customer({commit},{value}){
            commit('SET_EXISTING_CUSTOMER',{value});
        },
        get_csrf_token({ commit }, { csrf }) {
            commit('GET_CSRF_TOKEN');
        },
        get_all_customer_data({ commit }) {
            commit('GET_CSRF_TOKEN');
            commit('GET_ALL_CUSTOMER_DATA');
        },
        get_customer_data(context, args) {
            var that = this;
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url:
                        BASE_URL +
                        'fetch-data/' +
                        args.cosigner +
                        '/' +
                        args.form +
                        '/',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': this.state.csrfToken,
                    },
                }).then(
                    response => {
                        // If it's first cosigner and personal_info form get email and mobile_phone from registration data
                        resolve(response, args);
                        if (
                            this.state.current_cosigner === 1 &&
                            args.form === 'personal_info'
                        ) {
                            this.state.personal_info.email = $(
                                '#initialUserEmail'
                            ).val();
                            this.state.personal_info.mobile_phone = $(
                                '#initialUserMobilePhone'
                            ).val();
                        }
                        for (var j in that.state[args.form]) {
                            if (
                                j != null &&
                                response.data.hasOwnProperty(args.form)
                            ) {
                                that.state[args.form][j] =
                                    response.data[args.form][j];
                            }
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        async get_fc_file_data(context, args) {
            return axios({
                method: 'get',
                url: BASE_URL + 'ls/' + args.cosigner + '/FC/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.state.csrfToken,
                },
            });
        },
        async get_id_file_data({ commit }, args) {
            return axios({
                method: 'get',
                url: BASE_URL + 'ls/' + args.cosigner + '/CI/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.state.csrfToken,
                },
            });
        },
        get_total_cosigners({ commit }, args) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: BASE_URL + 'tc/',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': this.state.csrfToken,
                    },
                }).then(
                    response => {
                        resolve(response, args);
                        that.state.total_cosigners =
                            response.data.total_cosigners;
                        that.state.with_beneficiary =
                            response.data.with_beneficiary;
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        start_compilation({ commit }) {
            commit('HIDE_PREPARE_DOCUMENTS');
            commit('SET_ACTIVE_COSIGNER', { id: 1 });
        },
        set_active_cosigner({ commit }, { cosigner }) {
            commit('SET_ACTIVE_COSIGNER', { id: cosigner });
        },
        save_addresses: function({ commit }) {
            commit('SAVE_ADDRESSES');
        },
        save_data({ commit }, { cosigner, form, property }) {
            commit('GET_CSRF_TOKEN');
            commit('SAVE_DATA', { cosigner, form, property });
        },
        save_data_async({ commit }, { cosigner, form, property }) {
            commit('GET_CSRF_TOKEN');
            var that = this;
            var cc = { current_cosigner: that.state.current_cosigner };
            var pi = Object.assign(that.state[property], cc);
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: BASE_URL + form + '/',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRFToken': that.state.csrfToken,
                    },
                    data: pi,
                }).then(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        set_cf_files({ commit }, { documentFilesInfo }) {
            commit('SET_CF_FILES', { documentFilesInfo });
        },
        set_id_files({ commit }, { documentFilesInfo }) {
            commit('SET_ID_FILES', { documentFilesInfo });
        },
        set_uploadChanged({ commit }, { uploadChanged }) {
            commit('SET_IS_UPLOADED', { uploadChanged });
        },
        // Cosigners actions
        reset_total_cosigners: function({ commit }) {
            commit('RESET_TOTAL_COSIGNERS');
        },
        set_total_cosigners: function(
            { commit },
            { total_cosigners, with_beneficiary }
        ) {
            commit('GET_CSRF_TOKEN');
            commit('SET_TOTAL_COSIGNERS', {
                total_cosigners: total_cosigners,
                with_beneficiary,
            });
        },
        update_current_cosigner: function({ commit }) {
            commit('UPDATE_CURRENT_COSIGNER');
        },
        // UI actions
        back_to_previous_form: function({ commit }, { form }) {
            if (form === 'personal_info' && this.state.current_cosigner !== 1) {
                commit('BACK_TO_PREVIOUS_COSIGNER');
                router.go('-1');
            } else if (
                form === 'personal_info' &&
                this.state.current_cosigner === 1
            ) {
                commit('SHOW_PREPARE_DOCUMENTS');
                router.go('-1');
            } else {
                router.go('-1');
            }
        },
        next_form: function() {
            router.go('+1');
        },
        open_form: function({ commit }, { id }) {
            commit('OPEN_FORM', { id: id });
        },
        // Options actions
        get_districts({ commit }) {
            commit('GET_DISTRICTS');
        },
        get_docs({ commit }) {
            commit('GET_DOCS');
        },
        get_time({ commit }) {
            commit('GET_TIME');
        },
        get_service({ commit }) {
            commit('GET_SERVICE');
        },
        get_select_options({ commit }, { option }) {
            commit('GET_SELECT_OPTIONS', { option });
        },
        clean_personal_info({ commit }, { cosigner }) {
            commit('CLEAN_PERSONAL_INFO', { cosigner });
        },
        clean_addresses({ commit }, { cosigner }) {
            commit('CLEAN_ADDRESSES', { cosigner });
        },
        clean_documents({ commit }, { cosigner }) {
            commit('CLEAN_DOCUMENTS', { cosigner });
        },
    },
    getters: {
        cosigners(state) {
            return state.cosigners;
        },
        prepare_documents_visible: state => {
            return state.prepare_documents_visible;
        },
        activeCosigners: state => {
            return state.cosigners.filter(obj => obj.isActive);
        },
        editing_cosigner: state => {
            return state.cosigners.filter(obj => obj.isEditing);
        },
        current_cosigner_is_complete: (state, current_cosigner) => {
            const isActive = state.cosigners.filter(obj => obj.isComplete);
            if (isActive === current_cosigner) {
                return true;
            } else {
                return false;
            }
        },
        active_cosigners: (state, current_cosigner) => {
            return state.cosigners.filter(obj => obj.isActive);
        },
    },
    mutations: {
        // Data mutations
        SET_WM: function(state, is_wm) {
            // eslint-disable-next-line no-constant-condition
            state.wm = true ? is_wm.value.toLowerCase() === 'true' : false;
        },
        SET_EXISTING_CUSTOMER: function (state, existing_customer){
            state.existing_customer = true ? existing_customer.value.toLowerCase() === 'true': false;
        },
        GET_ALL_CUSTOMER_DATA: function(state, payload) {
            axios({
                method: 'get',
                url: BASE_URL + 'fetch-data-all/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    // const current_cosigner = that.state.current_cosigner
                    const current_cosigner = 'snd_signatory';
                    const data = response.data;
                    for (const cosignerData in data) {
                        if (data.hasOwnProperty(cosignerData)) {
                            if (cosignerData === current_cosigner) {
                                for (const formData in data[cosignerData]) {
                                    if (
                                        data[cosignerData].hasOwnProperty(
                                            formData
                                        )
                                    ) {
                                        if (formData === '') {
                                            // Object.assign(that.state, data[cosignerData])
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
                .catch(e => {
                    console.log('Unable to get all customers data');
                });
        },
        GET_CSRF_TOKEN: function(state) {
            state.csrfToken = cookie.get('csrftoken');
        },
        GET_CUSTOMER_DATA: function(state, payload) {
            const current_cosigner = payload.cosigner;
            const form = payload.form;
            axios({
                method: 'get',
                url:
                    BASE_URL +
                    'fetch-data/' +
                    current_cosigner +
                    '/' +
                    form +
                    '/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    // If it's first cosigner and personal_info form get email and mobile_phone from registration data
                    if (
                        state.current_cosigner === 1 &&
                        form === 'personal_info'
                    ) {
                        state.personal_info.email = $(
                            '#initialUserEmail'
                        ).val();
                        state.personal_info.mobile_phone = $(
                            '#initialUserMobilePhone'
                        ).val();
                    }
                    for (var j in state[form]) {
                        state[form][j] = response.data[form][j];
                    }
                    state.isLoading = false;
                })
                .catch(e => {});
        },
        SAVE_DATA: function(state, payload) {
            var cc = { current_cosigner: state.current_cosigner };
            var pi = Object.assign(state[payload.property], cc);
            axios({
                method: 'post',
                url: BASE_URL + payload.form + '/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
                data: pi,
            })
                .then(function(response) {
                    console.log(response);
                })
                .catch(e => {
                    console.log(e);
                    return false;
                });
        },
        SET_CF_FILES(state, documentFilesInfo) {
            state.upload_documents.fcFiles.push(
                documentFilesInfo.documentFilesInfo
            );
        },
        SET_ID_FILES(state, documentFilesInfo) {
            state.upload_documents.idFiles.push(
                documentFilesInfo.documentFilesInfo
            );
        },
        // Cosigners mutations
        RESET_TOTAL_COSIGNERS: state => {
            state.total_cosigners = 0;
            for (var c in state.cosigners) {
                const val = state.cosigners[c];
                val.isActive = false;
            }
            state.prepare_documents_visible = false;
        },
        SET_ACTIVE_COSIGNER: (state, { id }) => {
            while (state.cosigners.c == id) {
                state.cosigners.c++;
                this.isActive = true;
            }
            state.cosigners[0].isEditing = true;
        },
        SET_TOTAL_COSIGNERS: (state, { total_cosigners, with_beneficiary }) => {
            state.current_cosigner = 1;
            for (var c in state.cosigners) {
                const val = state.cosigners[c];
                if (val.id <= total_cosigners) {
                    val.isActive = true;
                }
            }
            state.cosigners[0].isActive = state.cosigners[0].isEditing = state.prepare_documents_visible = true;
            state.total_cosigners = total_cosigners;
            state.with_beneficiary = with_beneficiary;

            var pi = {
                total_cosigners: total_cosigners,
                with_beneficiary: with_beneficiary,
            };
            axios({
                method: 'post',
                url: BASE_URL + 'tc/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
                data: pi,
            })
                .then(function(response) {})
                .catch(e => {});
        },
        UPDATE_CURRENT_COSIGNER: function(state) {
            if (state.current_cosigner < state.total_cosigners) {
                for (var c in state.cosigners) {
                    const cosigner = state.cosigners[c];
                    if (cosigner.id === state.current_cosigner) {
                        cosigner.isActive = false;
                        cosigner.isComplete = true;
                        cosigner.isEditing = false;
                    }
                }
            }
            state.current_cosigner++;
            for (var d in state.cosigners) {
                const e = state.cosigners[d];
                if (e.id === state.current_cosigner) {
                    e.isActive = true;
                    e.isEditing = true;
                }
            }
        },
        BACK_TO_PREVIOUS_COSIGNER: function(state) {
            if (state.current_cosigner !== 1) {
                for (var c in state.cosigners) {
                    const cosigner = state.cosigners[c];
                    if (cosigner.id === state.current_cosigner) {
                        cosigner.isActive = false;
                        cosigner.isComplete = false;
                        cosigner.isEditing = false;
                    }
                }
                state.current_cosigner--;
                for (var d in state.cosigners) {
                    const e = state.cosigners[d];
                    if (e.id === state.current_cosigner) {
                        e.isActive = true;
                        e.isEditing = true;
                        e.isComplete = false;
                    }
                }
            }
        },
        // UI mutations
        HIDE_PREPARE_DOCUMENTS: state => {
            state.prepare_documents_visible = false;
        },
        SHOW_PREPARE_DOCUMENTS: state => {
            state.prepare_documents_visible = true;
        },
        OPEN_FORM: (state, { id }) => {
            for (var c in state.cosigners) {
                const val = state.cosigners[c];
                if (val.id === id) {
                    val.isEditing = true;
                } else {
                    val.isEditing = false;
                }
            }
        },
        // Options mutations
        GET_DISTRICTS: function(state) {
            axios({
                method: 'get',
                url: BASE_URL + 'fetch-data-comp/geo/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    state.options.districts = response.data.districts;
                })
                .catch(e => {});
        },
        GET_DOCS: function(state) {
            axios({
                method: 'get',
                url: BASE_URL + 'fetch-data-comp/doc/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    state.options.document_type = response.data.document_type;
                    state.options.released_by = response.data.released_by;
                })
                .catch(e => {});
        },
        GET_TIME: function(state) {
            axios({
                method: 'get',
                url: BASE_URL + 'fetch-data-comp/time/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    state.options.days = response.data.days;
                    state.options.months = response.data.months;
                    state.options.years = response.data.years_birthday;
                    state.options.years_expiring_doc =
                        response.data.years_expiring_doc;
                    state.options.years_releasing_doc =
                        response.data.years_releasing_doc;
                })
                .catch(e => {});
        },
        GET_SERVICE: function(state) {
            axios({
                method: 'get',
                url: BASE_URL + 'fetch-data-comp/service/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    state.service = response.data.service
                    state.loadService = true
                })
                .catch(e => {});
        },
        GET_SELECT_OPTIONS: function(state, option) {
            axios({
                method: 'get',
                url: BASE_URL + 'get_select_options/' + option + '/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': state.csrfToken,
                },
            })
                .then(function(response) {
                    console.log(response.data);
                })
                .catch(e => {});
        },
        CLEAN_FORM: (state, { form }) => {
            Object.keys(state[form]).map(key => (state[form][key] = undefined));
        },
    },
    state: {
        wm: false,
        existing_customer: false,
        current_cosigner: 1,
        csrfToken: null,
        same_as: 1,
        total_cosigners: 0,
        service: '',
        loadService: false,
        with_beneficiary: false,
        prepare_documents_visible: false,
        isLoading: true,
        addresses: {
            address: undefined,
            address_cap: undefined,
            address_city: undefined,
            address_country: undefined,
            address_city_ref: undefined,
            address_number: undefined,
            domicilio_address: undefined,
            domicilio_cap: undefined,
            domicilio_city: undefined,
            domicilio_city_ref: undefined,
            domicilio_number: undefined,
        },
        contract: {
            fatca: undefined,
            presa_visione: undefined,
        },
        cosigners: [
            {
                id: 1,
                num: 1,
                isActive: false,
                isComplete: false,
                isEditing: false,
                withBeneficiary: false,
                selectText: 'Solo a me',
                title: 'del primo intestatario',
            },
            {
                id: 2,
                num: 2,
                isActive: false,
                isComplete: false,
                isEditing: false,
                withBeneficiary: false,
                selectText: "A me e ad un'altra persona",
                title: 'del secondo intestatario',
            },
            {
                id: 3,
                num: 3,
                isActive: false,
                isComplete: false,
                isEditing: false,
                withBeneficiary: false,
                selectText: 'A me e ad altre due persone',
                title: 'del terzo intestatario',
            },
        ],
        documents: {
            id_document_expiring_day: undefined,
            id_document_expiring_month: undefined,
            id_document_expiring_year: undefined,
            id_document_number: undefined,
            id_document_releasing_city: undefined,
            id_document_releasing_city_ref: undefined,
            id_document_releasing_day: undefined,
            id_document_releasing_month: undefined,
            id_document_releasing_year: undefined,
            id_document_type: undefined,
        },
        options: {
            cities: {},
            countries: {},
            days: {},
            districts: {},
            document_type: {},
            months: {},
            released_by: {},
            suggestions: {},
            years_expiring_doc: {},
            years_releasing_doc: {},
            years: {},
        },
        personal_info: {
            mobile_phone: undefined,
            name: undefined,
            surname: undefined,
            email: undefined,
            fiscal_code: undefined,
            family_link: undefined,
            iban: undefined,
        },
        upload_documents: {
            upload_later: false,
            fcFiles: [],
            idFiles: [],
            // uploadChanged: false
        },
        constraints: {
            family_link: [
                { label: 'Genitore - Figlio/a', value: 'P' },
                { label: 'Nonno/a - Nipote', value: 'GP' },
            ],
        },
    },
});
