import store from '../../store/onboarding';
import { setupValidation } from '../../services/validation';
import _ from 'lodash';
import i18n from 'i18next';
import $ from 'jquery';
import Autocomplete from 'vue2-autocomplete-js';
require('vue2-autocomplete-js/dist/style/vue2-autocomplete.css');

export default {
    beforeCreate() {
        store.state.isLoading = true;
        var form = this.$options.name;
        store.commit('CLEAN_FORM', { form });
    },
    created() {
        // eslint-disable-next-line no-unused-vars
        var that = this;
        const cosigner = this.$store.state.current_cosigner;
        const form = this.$options.name;
        if (this.$store.state.wm) this.wm = true;

        this.$store.dispatch('get_customer_data', { cosigner, form }).then(
            // eslint-disable-next-line no-unused-vars
            response => {
                if (store.state.addresses.address_city != undefined) {
                    const savedCity = store.state.addresses.address_city;
                    this.$refs.autocompletez.setValue(savedCity);
                }
                if (store.state.addresses.domicilio_city != undefined) {
                    this.same_as = 2;
                    const savedCity = store.state.addresses.domicilio_city;
                    this.$refs.autocompletez_home.setValue(savedCity);
                }
                store.state.isLoading = false;
            },
            error => {
                store.state.isLoading = false;
                console.log(error);
            }
        );
        this.$store.state.addresses.address_country = 'I';
    },
    components: { Autocomplete },
    computed: {
        current_cosigner: () => store.state.current_cosigner,
        address: () => i18n.t('Components.Addresses.address'),
        number: () => i18n.t('Components.Addresses.number'),
        cap: () => i18n.t('Components.Addresses.cap'),
        city: () => i18n.t('Components.Addresses.city'),
        district: () => i18n.t('Components.Addresses.district'),
        domicilioAddress: () => i18n.t('Components.Addresses.domicilioAddress'),
        domicilioNumber: () => i18n.t('Components.Addresses.domicilioNumber'),
        domicilioCap: () => i18n.t('Components.Addresses.domicilioCap'),
        domicilioCity: () => i18n.t('Components.Addresses.domicilioCity'),
        domicilioDistrict: () =>
            i18n.t('Components.Addresses.domicilioDistrict'),
        isLoading: () => store.state.isLoading,
        sameAddresses: () => i18n.t('Components.Addresses.sameAddresses'),
        addresses: () => store.state.addresses,
        editButton: () => i18n.t('General.editButton'),
        compileButton: () => i18n.t('General.compileButton'),
        previousFormButton: () => i18n.t('General.previousFormButton'),
        saveButton: () => i18n.t('General.saveButton'),
    },
    data: function() {
        return {
            city_search_url: '/onboarding/city',
            cosigners: _.cloneDeep(store.state.cosigners),
            with_beneficiary: _.cloneDeep(store.state.with_beneficiary),
            preContent: '',
            same_as: 1,
            show_error: false,
        };
    },
    destroyed() {
        // sessionStorage.setItem('breadcrumb', JSON.stringify({cosigner: store.state.current_cosigner, step: 3}))
        store.state.isLoading = true;
    },
    methods: {
        processJSON(data) {
            if (data.length > 0) {
                var results = [];
                data.forEach(function(item) {
                    let longCity = item.city + ' (' + item.district + ')';
                    let notItalianCity = item.city + ', ' + item.country;
                    results.push({
                        city_ref: item.city_ref,
                        long_city: longCity,
                        city:
                            item.country == 'Italia'
                                ? longCity
                                : notItalianCity,
                    });
                });
                return results;
            }
            return [];
        },
        handleSelect(data) {
            const selectedCity = data.city;
            this.$store.state.addresses.address_city = selectedCity;
            this.$store.state.addresses.address_city_ref = data.city_ref;
            this.$refs.autocompletez.setValue(data.long_city);
        },
        handleSelectHome(data) {
            const selectedCity = data.city;
            this.$store.state.addresses.domicilio_city = selectedCity;
            this.$store.state.addresses.domicilio_city_ref = data.city_ref;
            this.$refs.autocompletez_home.setValue(data.long_city);
        },
        setValue(data) {
            this.$refs.autocompletez.setValue(data.long_city);
            this.$refs.autocompletez_home.setValue(data.long_city);
        },
        nextForm() {
            const form = 'addressinfo';
            const cosigner = store.state.current_cosigner;
            const property = 'addresses';
            $('.on-an__form')
                .parsley()
                .isValid()
                ? store
                      .dispatch('save_data_async', { cosigner, form, property })
                      .then(response => {
                          response.status !== 200
                              ? (this.show_error =
                                    true &&
                                    this.$raven.captureException(
                                        new Error(
                                            'The response status id :' +
                                                response.status
                                        )
                                    ))
                              : this.$router.push({ name: 'contratto' });
                      })
                      .catch(error => {
                          this.$raven.captureException(
                              new Error('The error is :' + error)
                          );
                          this.show_error = true;
                      })
                : $('.on-an__form')
                      .parsley()
                      .validate();
        },
        back_to_previous_form() {
            event.preventDefault();
            const form = 'addressinfo';
            store.dispatch('back_to_previous_form', { form });
        },
        passCityToDistrict(selecteCity) {
            this.data.selectedCity = selecteCity;
        },
        setActiveOptionAddresses(option) {
            if (option.sigla)
                store.state.addresses.address_district = option.sigla;
        },
    },
    mounted: function() {
        // Init validation on the component fields
        setupValidation();
    },
    name: 'addresses',
    template: '</addresses>',
};
