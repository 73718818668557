import {removeValidation} from '../../services/validation';
import store from '../../store/onboarding';
import router from '../../router/onboarding/router';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import _ from 'lodash';
import cookie from 'js-cookie';
import i18n from 'i18next';
import axios from 'axios';

// uploadChanged is FALSE in init (at the store), the first time
// after, when the page uploads all the files becames TRUE
// it returns FALSE if a file is removed

export default {
    beforeCreate() {
        this.isLoading = true;
        var form = this.$options.name;
        store.commit('CLEAN_FORM', {form});
    },
    created() {
        this.parallelChargeAll()
            .then(() => {
                this.isLoading = false;
            })
            .catch(e => console.log(e));
        if (this.$store.state.existing_customer) this.existing_customer = true;
    },
    components: {
        vue2Dropzone,
    },
    computed: {
        compileButton: () => i18n.t('General.compileButton'),
        current_cosigner_is_complete: () =>
            store.getters.current_cosigner_is_complete,
        editButton: () => i18n.t('General.editButton'),
        fcFiles: () => store.state.upload_documents.fcFiles,
        fiscalDocumentBoxTitle: () =>
            i18n.t('Components.UploadDocuments.fiscalDocumentBoxTitle'),
        goToAmlButton: () => i18n.t('General.goToAmlButton'),
        idFiles: () => store.state.upload_documents.idFiles,
        identityDocumentBoxTitle: () =>
            i18n.t('Components.UploadDocuments.identityDocumentBoxTitle'),
        previousFormButton: () => i18n.t('General.previousFormButton'),
        saveButton: () => i18n.t('General.saveButton'),
        subTitle: () => i18n.t('Components.UploadDocuments.subTitle'),
        total_cosigners: () => store.state.total_cosigners,
        uploadLaterCondition: () =>
            i18n.t('Components.UploadDocuments.uploadLaterCondition'),
        upload_documents: () => store.state.upload_documents,
        upload_later: () => store.state.upload_documents.upload_later,
        validationMessage: () =>
            i18n.t('Components.UploadDocuments.validationMessage'),
        warningUpload: () => i18n.t('Components.UploadDocuments.warningUpload'),
    },
    data: function () {
        return {
            cosigners: _.cloneDeep(store.state.cosigners),
            current_cosigner: store.state.current_cosigner,
            existing_customer: false,
            with_beneficiary: _.cloneDeep(store.state.with_beneficiary),
            fiscalCodeOptions: {
                autoProcessQueue: false,
                url:
                    '/onboarding/up/' +
                    this.$store.state.current_cosigner +
                    '/fc/',
                thumbnailWidth: 100,
                maxFiles: 4,
                maxFilesize: 10, // MB
                addRemoveLinks: true,
                dictDefaultMessage: 'Carica il codice fiscale',
                acceptedFiles:
                    'application/pdf,image/png,image/tiff,image/jpeg',
                headers: {
                    'X-CSRFToken': cookie.get('csrftoken'),
                    withCredentials: true,
                    credentials: 'same-origin',
                    'Access-Control-Allow-Headers': 'X-File-Name',
                },
            },
            isLoading: true,
            idDocOptions: {
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 2,
                url:
                    '/onboarding/up/' +
                    this.$store.state.current_cosigner +
                    '/id/',
                thumbnailWidth: 100,
                maxFiles: 6,
                maxFilesize: 10, // MB
                addRemoveLinks: true,
                dictDefaultMessage: "Carica il documento d'identità",
                acceptedFiles:
                    'application/pdf,image/png,image/tiff,image/jpeg',
                headers: {
                    'X-CSRFToken': cookie.get('csrftoken'),
                    withCredentials: true,
                    credentials: 'same-origin',
                    'Access-Control-Allow-Headers': 'X-File-Name',
                },
            },
            validationError: false,
        };
    },
    destroyed() {
        store.state.isLoading = true;
        sessionStorage.setItem(
            'breadcrumb',
            JSON.stringify({cosigner: store.state.current_cosigner, step: 6})
        );
    },
    methods: {
        async fcInDb() {
            var fileList = [];
            const cosigner = store.state.current_cosigner;
            try {
                const response = await this.$store.dispatch(
                    'get_fc_file_data',
                    {cosigner}
                );
                fileList = response.data;
            } catch (err) {
                console.log(err);
                console.log(err.response);
            }
            return fileList;
        },
        async idInDb() {
            var fileList = [];
            const cosigner = store.state.current_cosigner;
            try {
                const response = await this.$store.dispatch(
                    'get_id_file_data',
                    {cosigner}
                );
                fileList = response.data;
            } catch (err) {
                console.log(err);
                console.log(err.response);
            }
            return fileList;
        },
        async fcInDbDifferFromDZ() {
            var inDb = await this.fcInDb();
            if (inDb.length == 0) return true;
            if (this.fcFiles.length > 0) {
                var addedFileUuid = this.fcFiles[0].uuid;
                var dbFileUuid = inDb[0].uuid;
                if (addedFileUuid != dbFileUuid) {
                    return true;
                }
            }
            return false;
        },
        async idInDbDifferFromDZ() {
            var inDb = await this.idInDb();
            if (inDb.length == 0) return true;
            var uuidInDb = [];
            inDb.forEach(ele => {
                uuidInDb.push(ele.uuid);
            });
            if (this.idFiles.length > 0) {
                var uuidInDz = [];
                this.idFiles.forEach(ele => {
                    uuidInDz.push(ele.uuid || ele.upload.uuid);
                });
                var diff = _.xor(uuidInDz, uuidInDb);
                if (diff.length > 0) return true;
            }
            return false;
        },
        validateAndGoNext() {
            this.validateAgain();
            if (!this.validationError) {
                if (this.current_cosigner < this.total_cosigners) {
                    store.dispatch('update_current_cosigner').then(function () {
                        router.push({name: 'informazioni-personali'});
                    });
                    return `new cosigner`;
                } else if (this.current_cosigner === this.total_cosigners) {
                    if (window.history) {
                        window.history.replaceState(
                            {},
                            'Anagrafica',
                            '/onboarding/anagrafica/'
                        );
                    }
                    window.location = this.existing_customer ? '/onboarding/skip-surveys/' : '/onboarding/questionario-aml/';
                    return 'new page';
                }
            }
            return 'Default';
        },
        validateAgain() {
            var validFcFiles = [];
            this.fcFiles.forEach(f => {
                if (f.accepted) {
                    validFcFiles.push(f);
                }
            });

            var validIdFiles = [];
            this.idFiles.forEach(f => {
                if (f.accepted) {
                    validIdFiles.push(f);
                }
            });

            var thereIsFcFile = false;
            if (validFcFiles.length > 0) {
                thereIsFcFile = true;
            }

            var thereAreIdFiles = false;
            if (validIdFiles.length > 0) {
                thereAreIdFiles = true;
            }
            this.validationError = true;
            if ((thereIsFcFile && thereAreIdFiles) || this.upload_later) {
                this.validationError = false;
            }
        },
        async parallelDifferFromDZ() {
            const fcDiffer = this.fcInDbDifferFromDZ();
            const idDiffer = this.idInDbDifferFromDZ();
            return (await fcDiffer) || (await idDiffer);
        },
        async nextForm() {
            let utm_data = DATA_SIGNUP.utm_params;
            if (this.upload_later && !this.existing_customer) {
                // creiamo il dataLayer perché in questa pagina è undefined.
                window.dataLayer = window.dataLayer || [];
                // tag-manager 2
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'personaldata_complete',
                        eventLabel: 'UploadLater',
                        uid: utm_data.uid ? utm_data.uid : null,
                        campaign: utm_data.utm_campaign ? utm_data.utm_campaign : null,
                        source: utm_data.utm_source ? utm_data.utm_source : null,
                        medium: utm_data.utm_medium ? utm_data.utm_medium : null
                    });
                }
                this.deleteAllDocumentsAndGo();
            } else {
                this.parallelDifferFromDZ()
                    .then(result => {
                        this.validateAgain();
                        if (result && !this.validationError) {
                            this.deleteAllDocumentsAndGo();
                        }
                        if (!result) {
                            this.validateAndGoNext();
                            // creiamo il dataLayer perché in questa pagina è undefined.
                            window.dataLayer = window.dataLayer || [];
                            if (window.dataLayer) {
                                window.dataLayer.push({
                                    event: 'personaldata_complete',
                                    eventLabel: 'Uploaded',
                                    uid: utm_data.uid ? utm_data.uid : null,
                                    campaign: utm_data.utm_campaign ? utm_data.utm_campaign : null,
                                    source: utm_data.utm_source ? utm_data.utm_source : null,
                                    medium: utm_data.utm_medium ? utm_data.utm_medium : null
                                });
                            }
                        }
                    })
                    .catch(err => console.error(err));
            }
        },
        fcProcessQueue() {
            if (Array.isArray(this.$refs.fiscalCodeDropZone)) {
                this.$refs.fiscalCodeDropZone[0].processQueue();
            } else {
                this.$refs.fiscalCodeDropZone.processQueue();
            }
            this.idProcessQueue();
        },
        idProcessQueue() {
            if (Array.isArray(this.$refs.idDocDropZone)) {
                this.$refs.idDocDropZone[0].processQueue();
            } else {
                this.$refs.idDocDropZone.processQueue();
            }
            setTimeout(() => {
                this.validateAndGoNext();
            }, 2000);
        },
        back_to_previous_form() {
            const form = 'upload_documents';
            store.dispatch('back_to_previous_form', {form});
        },
        async storeFCFilesInfo(file) {
            const alreadyStored = await this.fcInDb();
            const uuids = [];
            alreadyStored.forEach(ele => {
                uuids.push(ele.uuid);
            });
            if (!uuids.includes(file.uuid)) {
                store.dispatch('set_cf_files', {documentFilesInfo: file});
            }
        },
        async storeIDFilesInfo(file) {
            const alreadyStored = await this.idInDb();
            const uuids = [];
            alreadyStored.forEach(ele => {
                uuids.push(ele.uuid);
            });
            if (!uuids.includes(file.uuid)) {
                store.dispatch('set_id_files', {documentFilesInfo: file});
            }
        },
        async removeFcDocFile(file, error, xhr) {
            var that = this;
            var fileUuid = file.uuid || file.upload.uuid;
            const current_cosigner = this.$store.state.current_cosigner;
            axios({
                method: 'post',
                url: `/onboarding/${current_cosigner}/rm-doc/${fileUuid}/`,
                headers: {
                    'X-CSRFToken': this.$store.state.csrfToken,
                },
            })
                .then(function (response) {
                    that.$store.state.upload_documents.fcFiles = [];
                })
                .catch(err => {
                    if (err.response.data.noInDb) {
                        that.$store.state.upload_documents.fcFiles = [];
                    } else {
                        console.log(err);
                    }
                });
        },
        async removeIdDocFile(file, error, xhr) {
            var that = this;
            const current_cosigner = this.$store.state.current_cosigner;
            var fileUuid = file.uuid || file.upload.uuid;
            axios({
                method: 'post',
                url: `/onboarding/${current_cosigner}/rm-doc/${fileUuid}/`,
                headers: {
                    'X-CSRFToken': this.$store.state.csrfToken,
                },
            })
                .then(function (response) {
                    var filters_arr = that.idFiles.filter(ele => {
                        var eleUuid = ele.uuid || ele.upload.uuid;
                        return eleUuid !== fileUuid;
                    });
                    that.$store.state.upload_documents.idFiles = filters_arr;
                })
                .catch(err => {
                    if (err.response.data.noInDb) {
                        var filters_arr = that.idFiles.filter(ele => {
                            var eleUuid = ele.uuid || ele.upload.uuid;
                            return eleUuid !== err.response.data.noInDb;
                        });
                        that.$store.state.upload_documents.idFiles = filters_arr;
                    } else {
                        console.log(err);
                    }
                });
        },
        chargeFilesDropzone(docUUID, _type) {
            var that = this;
            axios({
                method: 'get',
                url:
                    '/onboarding/dl/' +
                    that.$store.state.current_cosigner +
                    '/' +
                    docUUID +
                    '/',
                headers: {
                    'X-CSRFToken': that.$store.state.csrfToken,
                },
            })
                .then(function (response) {
                    var fileName = response.headers['x-sendfile']
                        .split('/')
                        .pop();
                    var mimeType = response.headers['content-type'];
                    var blob = data2Blob(response.data, mimeType);
                    blob.name = fileName;
                    blob.uuid = docUUID;
                    var dropZoneItem;
                    var fileList;
                    if (_type === 'fc') {
                        fileList = that.fcFiles;
                        if (Array.isArray(that.$refs.fiscalCodeDropZone)) {
                            dropZoneItem = that.$refs.fiscalCodeDropZone[0];
                        } else {
                            dropZoneItem = that.$refs.fiscalCodeDropZone;
                        }
                    } else if (_type === 'id') {
                        fileList = that.idFiles;
                        if (Array.isArray(that.$refs.idDocDropZone)) {
                            dropZoneItem = that.$refs.idDocDropZone[0];
                        } else {
                            dropZoneItem = that.$refs.idDocDropZone;
                        }
                    }
                    dropZoneItem.addFile(blob);
                    const lastEle = fileList[fileList.length - 1];
                    lastEle.accepted = true;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async processQueues() {
            this.fcProcessQueue();
        },
        deleteAllDocumentsAndGo() {
            axios({
                method: 'post',
                url: `/onboarding/${this.$store.state.current_cosigner}/rm-all/`,
                headers: {
                    'X-CSRFToken': this.$store.state.csrfToken,
                },
            })
                .then(() => {
                    if (!this.upload_later) {
                        this.processQueues();
                    } else {
                        this.validateAndGoNext();
                    }
                })
                .catch(err => console.log('err2', err));
        },
        async chargeFilesOnFCDropzone() {
            const cosigner = store.state.current_cosigner;
            this.$store
                .dispatch('get_fc_file_data', {cosigner})
                .then(response => {
                    this.$store.state.upload_documents.fcFiles = response.data;
                    var fcDocUUID = this.upload_documents.fcFiles[0].uuid;
                    this.chargeFilesDropzone(fcDocUUID, 'fc');
                })
                .catch(err =>
                    console.log('customer has not upload files already', err)
                );
        },
        async chargeFilesOnIDDropzone() {
            const cosigner = store.state.current_cosigner;
            this.$store
                .dispatch('get_id_file_data', {cosigner})
                .then(response => {
                    this.$store.state.upload_documents.idFiles = response.data;
                    var idDocUUIDs = this.upload_documents.idFiles;
                    for (var each of idDocUUIDs) {
                        this.chargeFilesDropzone(each.uuid, 'id');
                    }
                })
                .catch(err =>
                    console.log('customer has not upload files already', err)
                );
        },
        async parallelChargeAll() {
            const chargeFC = this.chargeFilesOnFCDropzone();
            const chargeID = this.chargeFilesOnIDDropzone();
            const result = [await chargeFC, await chargeID];
        },
    },
    name: 'upload_documents',
    template: '</upload-documents>',
    watch: {
        getIDFiles: function (val) {
            this.idFiles = val;
        },
        getFCFiles: function (val) {
            this.fcFiles = val;
        },
        uploadLater: function () {
            if (this.upload_later) {
                removeValidation();
                this.isLoading = false;
            }
        },
    },
};

function data2Blob(dataString, mimestring) {
    var byteString = atob(dataString);

    var content = [];
    for (var i = 0; i < byteString.length; i++) {
        content[i] = byteString.charCodeAt(i);
    }
    return new Blob([new Uint8Array(content)], {type: mimestring});
}
