import i18n from 'i18next';
import '../../services/i18n/i18n';
import BarChart from '../../components/BarChart/BarChart.vue';
import Table from '../../components/Table/Table.vue';
import GeoDistribution from '../../components/GeoDistribution/GeoDistribution.vue';
import TimeLine from '../../components/TimeLine/TimeLine.vue';
import store from '../../store/privateArea';

export default {
    nampe: 'CompositionContainer',
    props: ['portfolioStatus'],
    template: '<CompositionContainer/>',
    components: {
        BarChart,
        Table,
        GeoDistribution,
        TimeLine,
    },
    computed: {
        title() {
            return i18n.t('Containers.CompositionContainer.title');
        },
        disclaimer() {
            return i18n.t('General.disclaimer');
        },
        currentStatusPortfolio() {
            if (store.getters.getCurrentContract) {
                var status = store.getters.getCurrentContract.status;
            }
            return status;
        },
    },
    watch: {
        currentStatusPortfolio() {},
    },
    created() {
        this.$on('status_monitor', value => {
            if (value == 'DATA_UPDATING') {
                var current_contract = store.getters.getCurrentContract;
                current_contract.status = value;
                store.commit('setCurrentContract', current_contract);
            }
        });
    },
};
