// eslint-disable-next-line no-unused-vars
import Amcharts from 'amcharts3';
// eslint-disable-next-line no-unused-vars
import AmSerial from 'amcharts3/amcharts/serial';

export default {
    props: {
        bars: null,
    },
    data() {
        return {
            assetClasses: [],
            maxValue: 50,
        };
    },
    methods: {
        drawBarChart() {
            if (this.chart != null) {
                this.chart.dataProvider = assetClasses;
                this.chart.validateData();
                this.chart.animateAgain();
            } else {
                this.$nextTick(function() {
                    this.chart = window.AmCharts.makeChart('chart', {
                        type: 'serial',
                        theme: 'light',
                        fontFamily: 'Inter Ui, sans-serif',
                        pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
                        path: '/static/fe/amcharts',
                        fontSize: 14,
                        dataProvider: this.assetClasses,
                        balloon: {
                            enabled: false,
                        },
                        responsive: {
                            enabled: true,
                        },
                        valueAxes: [
                            {
                                maximum: this.maxValue,
                                minimum: 0,
                                axisAlpha: 0,
                                dashLength: 0,
                                position: 'bottom',
                                gridThickness: 0,
                                inside: false,
                            },
                        ],
                        graphs: [
                            {
                                disableMouseEvents: true,
                                colorField: 'color',
                                cornerRadiusTop: 0,
                                fillAlphas: 1,
                                lineAlpha: 0,
                                type: 'column',
                                valueField: 'value',
                                labelText: '[[value]]%',
                                labelPosition: 'top',
                                labelOffset: 10,
                                fontFamily: 'Inter Ui, sans-serif',
                                fontSize: 14,
                                labelFunction: function(value) {
                                    return (
                                        value.values.value
                                            .toString()
                                            .replace('.', ',') + '%'
                                    );
                                },
                            },
                        ],
                        marginTop: 0,
                        marginRight: 0,
                        marginLeft: 0,
                        marginBottom: 48,
                        autoMargins: false,
                        categoryField: 'name',
                        categoryAxis: {
                            axisAlpha: 0,
                            gridAlpha: 0,
                            inside: true,
                            position: 'bottom',
                            tickLength: 0,
                            color: '#000',
                            fontSize: 14,
                            // fontFamily: 'Inter UI',
                            labelOffset: -32,
                            autoWrap: true,
                            classNameField: 'Chart__axis',
                        },
                        export: {
                            enabled: false,
                        },
                        hideCredits: true,
                    });
                });
            }
        },
    },
    created() {
        if (this.bars) {
            var that = this;
            // eslint-disable-next-line no-unused-vars
            this.bars.forEach(function(asset, index) {
                that.assetClasses.push(asset);
                if (Number(asset.value) > that.maxValue) {
                    that.maxValue = Number(asset.value) > 60 ? 110 : 60;
                }
            });
            this.drawBarChart();
        }
    },
};
