import '../../services/i18n/i18n';
import router from '../../router/privateArea/router';
import store from '../../store/privateArea';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import Amcharts from 'amcharts3';
// eslint-disable-next-line no-unused-vars
import AmSerial from 'amcharts3/amcharts/serial';

const BASE_URL = '/wm/api/v1/mandato/';
const ALLOCATION_URL = '/asset/allocation/';
const RISK_URL = '/asset/risk';

const FAKE_DATA =
    '[{"asset_class":"Obbligazionario Breve Termine","color":"#315D72","value":"35.07"},' +
    '{"asset_class":"Obbligazionario","color":"#949BAA","value":"13.46"},' +
    '{"asset_class":"Credito","color":"#D58629","value":"33.39"},' +
    '{"asset_class":"Azionario","color":"#BB2E2F","value":"5.34"},' +
    '{"asset_class":"Investimenti Alternativi","color":"#4C273F","value":"5.56"}]';

export default {
    data() {
        return {
            url: null,
            loading: true,
            bars: [],
            portfolio:null,
            chart: null,
        };
    },
    methods: {
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.bars = response.data.results;
                    this.loading = false;
                    this.drawBarChart();
                })
                .catch(e => {
                    console.log(e);
                });
        },
        fetchFakeData() {
            this.bars = JSON.parse(FAKE_DATA);
            this.drawBarChart();
            this.loading = false;
        },
        drawBarChart() {
            var assetClasses = [];
            var maxValue = 50;
            // eslint-disable-next-line no-unused-vars
            var that = this;
            this.bars.forEach(function(asset, index) {
                if(asset) {
                    if (asset.asset_class) {
                assetClasses.push({
                    name: asset.asset_class,
                    points: asset.value,
                    color: asset.color,
                });
                if (Number(asset.value) > maxValue) {
                    maxValue = Number(asset.value) > 58 ? 110 : 60;
                }
                    } else if (asset.portfolio) {
                        that.portfolio = asset.portfolio;
                        $(".CompositionContainer__title h3").first().html("Composizione del portafoglio - <span style='font-size:18px;'>"+ that.portfolio+"</span>");
                    }
                }
            });

            if (this.chart != null) {
                this.chart.dataProvider = assetClasses;
                this.chart.validateData();
                this.chart.animateAgain();
            } else {
                this.chart = window.AmCharts.makeChart('chart', {
                    type: 'serial',
                    theme: 'light',
                    pathToImages: 'https://cdn.amcharts.com/lib/3/images/',
                    path: '/static/fe/amcharts',
                    // fontFamily: 'Inter Ui',
                    fontSize: 12,
                    dataProvider: assetClasses,
                    balloon: {
                        enabled: false,
                    },
                    responsive: {
                        enabled: true,
                    },
                    valueAxes: [
                        {
                            maximum: maxValue,
                            minimum: 0,
                            axisAlpha: 0,
                            dashLength: 0,
                            position: 'bottom',
                            gridThickness: 0,
                            inside: false,
                        },
                    ],
                    graphs: [
                        {
                            disableMouseEvents: true,
                            colorField: 'color',
                            cornerRadiusTop: 0,
                            fillAlphas: 1,
                            lineAlpha: 0,
                            type: 'column',
                            valueField: 'points',
                            labelText: '[[value]]%',
                            labelPosition: 'top',
                            labelOffset: 10,
                            fontSize: 14,
                            // fontFamily: 'Inter UI'
                        },
                    ],
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: 0,
                    marginBottom: 48,
                    autoMargins: false,
                    categoryField: 'name',
                    categoryAxis: {
                        axisAlpha: 0,
                        gridAlpha: 0,
                        inside: true,
                        position: 'bottom',
                        tickLength: 0,
                        color: '#000',
                        fontSize: 14,
                        // fontFamily: 'Inter UI',
                        labelOffset: -32,
                        autoWrap: true,
                        classNameField: 'Chart__axis',
                    },
                    export: {
                        enabled: false,
                    },
                    hideCredits: true,
                });
            }
        },
    },
    computed: {
        getCurrentContract() {
            return store.getters.getCurrentContract;
        },
    },
    watch: {
        getCurrentContract: function(val) {
            this.contract = val;
            this.url = BASE_URL + this.contract.cod;
            this.url +=
                router.currentRoute.name === 'risk' ? RISK_URL : ALLOCATION_URL;
            if (this.contract.status != 'INACTIVE') {
                this.fetchData();
            } else {
                this.fetchFakeData();
            }
        },
    },
    created() {
        if (store.mandato != null && store.contract_status != null) {
            this.url = BASE_URL + store.mandato;
            this.url +=
                router.currentRoute.name === 'risk' ? RISK_URL : ALLOCATION_URL;
            if (store.contract_status != 'INACTIVE') {
                this.fetchData();
            } else {
                this.fetchFakeData();
                var that = this;
                setTimeout(function() {
                    that.fetchFakeData();
                }, 500);
            }
        } else {
            this.fetchFakeData();
            var out = this;
            setTimeout(function() {
                out.fetchFakeData();
            }, 500);
        }
    },
};
