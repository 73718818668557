import i18n from 'i18next';
import '../../services/i18n/i18n';
import Table from '../../components/Table/Table.vue';
import LineChart from '../../components/LineChart/LineChart.vue';
import Deposit from '../../components/Deposit/Deposit.vue';
import TimeSwitch from '../../components/TimeSwitch/TimeSwitch.vue';
import moment from 'moment';
import store from '../../store/privateArea';
import axios from 'axios';

const BASE_URL = '/wm/api/v1/mandato/';
const HISTORY_URL = '/historical_performance/';

export default {
    name: 'TrendContainer',
    template: '<TrendContainer/>',
    filters: {
        formatDate: function(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        percentage: function(value) {
            if (value) {
                value = value.toString().replace('.', ',');
                return value + '%';
            }
        },
    },
    components: {
        TimeSwitch,
        LineChart,
        Table,
        Deposit,
    },
    data() {
        return {
            url: null,
            history: null,
            refDate: null,
            horizon: null,
            rendimento: null,
            tmw: true, //default false --> Money Weighted
        };
    },
    computed: {
        historyToolTip() {
            return i18n.t('Containers.TrendContainer.historyTooltip');
        },
        MW() {
            return i18n.t('Containers.TrendContainer.MW');
        },
        TW() {
            return i18n.t('Containers.TrendContainer.TW');
        },
        title() {
            return i18n.t('Containers.TrendContainer.title');
        },
        disclaimer() {
            return i18n.t('General.disclaimer');
        },
    },
    updated() {
        $('[data-toggle="popover"]').popover({ html: true, trigger: 'hover' });
    },
    methods: {
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.history = response.data.results;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        setRefDate(value) {
            this.refDate = value;
        },
        setHorizon(value) {
            this.horizon = value;
        },
        setRendimento(value) {
            this.rendimento = value;
        },
    },
    created() {
        this.url = BASE_URL + store.mandato;
        this.url += HISTORY_URL;
        this.fetchData();
    },
};
