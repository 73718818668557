import i18n from 'i18next';
import Card from '../Card/Card.vue';
// eslint-disable-next-line no-unused-vars
import Amcharts from 'amcharts3';
// eslint-disable-next-line no-unused-vars
import AmPie from 'amcharts3/amcharts/pie';

export default {
    props: {
        elements: null,
    },
    data() {
        return {
            chart: null,
        };
    },
    methods: {
        drawDonutChart() {
            if (this.assets_classes.length > 0) {
                if (this.chart != null) {
                    this.chart.dataProvider = this.assets_classes;
                    this.chart.validateData();
                    this.chart.animateAgain();
                } else {
                    this.$nextTick(function() {
                        this.chart = window.AmCharts.makeChart('chartdiv', {
                            hideCredits: true,
                            type: 'pie',
                            theme: 'light',
                            fontSize: 14,
                            startDuration: 0,
                            addClassNames: true,
                            fontFamily: 'Inter Ui, sans-serif',
                            legend: {
                                position: 'right',
                                marginRight: 100,
                                autoMargins: false,
                                labelText: '[[title]]',
                                valueText: '[[value]]%',
                                markerLabelGap: 15,
                                valueFunction: function(value) {
                                    return (
                                        value.dataContext.value
                                            .toString()
                                            .replace('.', ',') + '%'
                                    );
                                },
                            },
                            radius: 200,
                            innerRadius: '70%',
                            dataProvider: this.assets_classes,
                            valueField: 'value',
                            titleField: 'name',
                            colorField: 'color',
                            export: {
                                enabled: true,
                            },
                            balloon: {
                                fixedPosition: true,
                            },
                            labelFunction: function(value) {
                                return (
                                    value.dataContext.name +
                                    ' ' +
                                    value.dataContext.value
                                        .toString()
                                        .replace('.', ',') +
                                    '%'
                                );
                            },
                        });
                        this.chart.invalidateSize();
                    });
                }
            }
        },
    },
    computed: {
        assets_classes() {
            var assetClasses = [];
            if (this.elements.length > 0) {
                // eslint-disable-next-line no-unused-vars
                this.elements.forEach(function(asset, index) {
                    assetClasses.push({
                        name: asset.asset_class,
                        value: (asset.value * 100).toFixed(3),
                        color: asset.color,
                    });
                });
            }
            return assetClasses;
        },
        title() {
            return i18n.t('Components.Portfolio.Donut.title');
        },
    },
    watch: {},
    created() {
        this.drawDonutChart();
    },
    components: {
        Card,
    },
};
