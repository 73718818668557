import $ from 'jquery';
import { setupValidation } from '../../services/validation';
import store from '../../store/onboarding';
import i18n from 'i18next';

export default {
    beforeCreate() {
        var cosigner = this.$store.state.current_cosigner;
        var form = this.$options.name;
        store.state.isLoading = true;
        store.commit('CLEAN_FORM', { form });
        // Deny access to contract form for cosigners that are not the main cosigner
        if (cosigner > 1) this.$router.push({ name: 'documenti' });
    },
    created() {
        if (this.$store.state.wm)
            this.contract_file = '/static/documentazione-contrattuale.pdf';
        const cosigner = this.$store.state.current_cosigner;
        const form = this.$options.name;
        this.$store.dispatch('get_customer_data', { cosigner, form }).then(
            // eslint-disable-next-line no-unused-vars
            response => {
                store.state.isLoading = false;
            },
            error => {
                store.state.isLoading = false;
                console.log(error);
            }
        );
    },
    computed: {
        compileButton: () => i18n.t('General.compileButton'),
        contract: () => store.state.contract,
        declaration: () => i18n.t('Components.Contract.declaration'),
        declarationA: () => i18n.t('Components.Contract.declarationA'),
        declarationB: () => i18n.t('Components.Contract.declarationB'),
        declarationC: () => i18n.t('Components.Contract.declarationC'),
        editButton: () => i18n.t('General.editButton'),
        isLoading: () => store.state.isLoading,
        presaVisione: () => i18n.t('Components.Contract.presaVisione'),
        previousFormButton: () => i18n.t('General.previousFormButton'),
        saveButton: () => i18n.t('General.saveButton'),
        title: () => i18n.t('Components.Contract.title'),
    },
    data: function() {
        return {
            contract_file: '/static/documentazione-contrattuale.pdf',
            cosigners: this.$store.state.cosigners,
            current_cosigner: this.$store.state.current_cosigner,
            show_error: false,
            total_cosigners: this.$store.state.total_cosigners,
            existing_customer: this.$store.state.existing_customer
        };
    },
    destroyed() {
        store.state.isLoading = true;
        sessionStorage.setItem(
            'breadcrumb',
            JSON.stringify({ cosigner: store.state.current_cosigner, step: 4 })
        );
    },
    methods: {
        checkboxToggle() {
            if (this.contract.fatca) {
                $('#parsley-id-multiple-fatca').show();
            } else {
                $('#parsley-id-multiple-fatca').hide();
            }
        },
        nextForm() {
            const form = 'contract_fatca';
            const cosigner = store.state.current_cosigner;
            const property = 'contract';
            $('.on-an__form')
                .parsley()
                .isValid()
                ? store
                      .dispatch('save_data_async', { cosigner, form, property })
                      .then(response => {
                          response.status !== 200
                              ? (this.show_error =
                                    true &&
                                    this.$raven.captureException(
                                        new Error(
                                            'The response status id :' +
                                                response.status
                                        )
                                    ))
                              : this.$router.push({ name: 'documenti' });
                      })
                      .catch(error => {
                          this.$raven.captureException(
                              new Error('The error is :' + error)
                          );
                          this.show_error = true;
                      })
                : $('.on-an__form')
                      .parsley()
                      .validate();
        },
        downloadContract: function() {
            const checkbox = $('#presa-visione');
            if (checkbox.is(':checked')) {
                return false;
            } else {
                $('#presa-visione').prop('disabled', false);
                $('.presa-visione').removeClass('disabled');
            }
        },
        back_to_previous_form() {
            event.preventDefault();
            const form = 'fatca';
            store.dispatch('back_to_previous_form', { form });
        },
    },
    mounted: function() {
        // Init validation on the component fields
        setupValidation();
    },
    name: 'contract',
    template: '#form-contract',
};
