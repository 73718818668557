// prettier-ignore
/* eslint-disable no-unused-vars */
export function truncateDecimals(floatNumber, fixedDecimals) {
    var multiplier = 1;
    for (var i = 0; i < fixedDecimals; i++) {
        multiplier *= 10;
    }
    return Math.trunc(floatNumber * multiplier) / multiplier;
}

export function formatCurrency(value) {
    value = value.toString().replace('.', ',');
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '€';
}

export function refreshWeight(portfolio) {
    if (portfolio.calc.panoramica) {
        var splitted = portfolio.calc.panoramica['portfolio_bar']['split'];

        // reset weights
        portfolio.bricks.forEach(function(item, index) {
            item.weight = 0;
        });

        // set weights
        Object.keys(splitted.core).forEach(function(core_name) {
            portfolio.bricks.forEach(function(item, index) {
                if (item.name == core_name) {
                    item.weight = 100 * splitted.core[core_name];
                }
            });
        });

        Object.keys(splitted.satellite).forEach(function(core_name) {
            portfolio.bricks.forEach(function(item, index) {
                if (item.name == core_name) {
                    item.weight = 100 * splitted.satellite[core_name];
                }
            });
        });
    }

    return portfolio;
}

export function portfolioBricksToTotalWeight(bricks) {

    const selectedBricks = bricks.filter(function(resBrick) {
            if(resBrick && resBrick.brickWeighed && resBrick.brickWeighed.selected) {
            return resBrick.brickWeighed.selected === true;
            }
        });

    return selectedBricks.reduce(function(totalWeight, brick) {
        // console.log('portfolioBricksToTotalWeight:'+brick);
            return brick.brickWeighed.weight
                ? totalWeight + brick.brickWeighed.weight
                : totalWeight;
    }, 0);
}

export function portfolioBrickSelected(bricks) {
    if (bricks) {
        // console.log('portfolioBrickSelected:'+bricks)
        const selectedBricks = bricks.filter(function(resBrick) {
            if(resBrick && resBrick.brickWeighed && resBrick.brickWeighed.selected) {
            return resBrick.brickWeighed.selected === true;
            }
        });
        return selectedBricks.length;
    } else {
        return 0;
    }
}

export function getProposal(bricks) {
    var result = {};
    if (bricks) {
        const selectedBricks = bricks.filter(function(resBrick) {
            return resBrick.selected == true && resBrick.weight > 0;
        });

        selectedBricks.forEach(function(index) {
            result[index.id] = index.weight;
        });
    }
    return result;
}

export function getPortfolioLabel(index, bricks) {
    var label = index;
    if (bricks) {
        const selectedBricks = bricks.filter(function(resBrick) {
            return resBrick.name == index;
        });
        label = selectedBricks[0].label;
        // label = selectedBricks[0]?.label ?? index;
    }
    if (label == 'Alternative Absolute Return') label = 'Alt. Abs. Return';
    return label;
}

export function buildTrendLastTableRow(trend) {
    var result = ['', '']; // color, name
    result.push(
        String(
            truncateDecimals(trend.portfolio.portfolio_tot * 100, 2)
        ).replace('.', ',') + '%'
    );
    result.push(
        String(
            truncateDecimals(trend.benchmark.portfolio_tot * 100, 2)
        ).replace('.', ',') + '%'
    );
    return result;
}

function get_instruments(portfolio, asset_name, sub_asset_name) {
    var results = [];
    portfolio.instrument.forEach(function(index) {
        if (
            index.asset_class == asset_name &&
            index.sub_asset_class == sub_asset_name
        ) {
            // delete index['sub_asset_class'];
            // delete index['asset_class'];
            index.portfolio =
                String(truncateDecimals(index.value * 100, 2)).replace(
                    '.',
                    ','
                ) + '%';
            results.push(index);
        }
    });
    return results;
}

function get_sub_assets(portfolio, asset_name) {
    var results = [];
    portfolio.sub_asset_class.forEach(function(index) {
        if (index.asset_class == asset_name) {
            index.isOpen = false;
            index.instruments = get_instruments(
                portfolio,
                asset_name,
                index.sub_asset_class
            );
            index.name = index.sub_asset_class;
            index.portfolio =
                String(truncateDecimals(index.value * 100, 2)).replace(
                    '.',
                    ','
                ) + '%';
            // delete index['sub_asset_class'];
            // delete index['asset_class'];
            results.push(index);
        }
    });
    return results;
}

function renameProperty(obj_array, new_name, old_name) {
    obj_array.forEach(function(item) {
        item[new_name] = item[old_name];
        delete item[old_name];
    });
    return obj_array;
}

export function getCombinedRisk(combined_obj, volatility_obj) {
    var combined_array = getCombined(combined_obj);
    var volatility_array = getCombined(volatility_obj);

    volatility_array.forEach(function(asset) {
        asset.volatility_portfolio = asset.portfolio;
        asset.volatility_benchkmark = asset.benchmark;
        asset.subassets.forEach(function(subasset) {
            subasset.instruments = renameProperty(
                subasset.instruments,
                'volatility_portfolio',
                'portfolio'
            );
            subasset.volatility_portfolio = subasset.portfolio;
            // delete subasset.portfolio
        });
        // delete asset.portfolio
        // delete asset.benchmark
    });

    combined_array.forEach(function(asset) {
        var volatility_asset = volatility_array.filter(function(item) {
            if (item.name == asset.name) return item;
        })[0];
        if (volatility_asset) {
            asset.volatility_portfolio = volatility_asset.volatility_portfolio;
            asset.volatility_benchmark = volatility_asset.volatility_benchkmark;
            asset.weight = Math.ceil(asset.portfolio_raw);
            asset.subassets.forEach(function(subasset) {
                var volatility_subasset = volatility_asset.subassets.filter(
                    function(item) {
                        if (item.name == subasset.name) return item;
                    }
                )[0];
                if (volatility_subasset) {
                    subasset.volatility_portfolio =
                        volatility_subasset.volatility_portfolio;
                    subasset.instruments.forEach(function(instrument) {
                        var volatility_instrument = volatility_subasset.instruments.filter(
                            function(item) {
                                if (item.name == instrument.name) return item;
                            }
                        )[0];
                        if(volatility_instrument) {
                        instrument.volatility_portfolio =
                            volatility_instrument.volatility_portfolio;
                        }
                    });
                }
                subasset.weight = Math.ceil(subasset.value * 100);
            });
        }
    });
    return combined_array;
}

export function getCombined(combined_obj) {
    var benchmark = combined_obj.benchmark;
    var portfolio = combined_obj.portfolio;

    var benchmark_asset_class = benchmark.asset_class;

    var results = [];

    portfolio.asset_class.forEach(function(asset) {
        var result = {};
        result.isOpen = false;
        result.color = asset.color;
        result.name = asset.index;
        result.portfolio =
            String(truncateDecimals(asset.value * 100, 2)).replace('.', ',') +
            '%';
        result.portfolio_raw = asset.value * 100;
        var local_benchmark = benchmark_asset_class.filter(function(bench) {
            if (bench.color == asset.color) return bench;
        })[0];
        if (local_benchmark) {
            result.benchmark =
                String(
                    truncateDecimals(local_benchmark.value * 100, 2)
                ).replace('.', ',') + '%';
            result.benchmark_raw = local_benchmark.value;
        }
        result.subassets = get_sub_assets(portfolio, asset.index);
        results.push(result);
    });
    return results;
}

export function convertToPoints(index_value_obj) {
    var index = index_value_obj.index;
    var values = index_value_obj.values;
    var points = [];

    for (var i = 0; i < index.length; i++) {
        points.push({
            date: index[i],
            value: truncateDecimals(values[i] * 100, 2),
        });
    }

    return points;
}

export function prepareComposition(pesi_array,controvalore_array,spese_array){
    spese_array.forEach(function(index) {
        index.cost = String(truncateDecimals(index.value,2)).replace('.',',')+'%'
        index.name = index.asset_class
        var asset_peso = pesi_array.filter(function(asset){if(asset.color == index.color) return asset})[0]
        var asset_controvalore = controvalore_array.filter(function(asset){if(asset.color == index.color) return asset})[0]
        index.weight = String(truncateDecimals(asset_peso.value * 100,2)).replace('.',',')+'%'
        index.value = String(truncateDecimals(asset_controvalore.value,2)).replace('.',',')+'€'
        index.subassets = index.sub_asset_class
        index.subassets.forEach(function(sub) {
            sub.cost = String(truncateDecimals(sub.value ,2)).replace('.',',')+'%'
            sub.name = sub.sub_asset_class
            var sub_asset_peso = asset_peso.sub_asset_class.filter(function(sub_asset){if(sub_asset.sub_asset_class == sub.name) return sub_asset})[0]
            var sub_asset_controvalore = asset_controvalore.sub_asset_class.filter(function(sub_asset){if(sub_asset.sub_asset_class == sub.name) return sub_asset})[0]
            sub.weight = String(truncateDecimals(sub_asset_peso.value * 100,2)).replace('.',',')+'%'
            sub.value = String(truncateDecimals(sub_asset_controvalore.value,2)).replace('.',',')+'€'
            sub.instruments.forEach(function(instrument) {
                var instrument_peso = sub_asset_peso.instruments.filter(function(i){if(i.name == instrument.name) return i.value})[0]
                instrument.weight = String(truncateDecimals(instrument_peso.value * 100,2)).replace('.',',')+'%'
                instrument.cost = String(truncateDecimals(instrument.value,2)).replace('.',',')+'%'
                delete instrument.value
            })
        })
        //delete index.sub_asset_class
    });
    return spese_array;
}

export function prepareAssetClassBarChartData(asset_array) {
    var results = [];
    asset_array.forEach(function(index) {
        var result = {
            name: index.asset_class,
            value: truncateDecimals(index.value * 100, 2),
            color: index.color,
        };
        results.push(result);
    });
    return results;
}

export function uuid4() {
    return 'xxxyxxyxxxyxxxxy'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
