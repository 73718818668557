import color from 'color';

export default {
    props: {
        columns: null,
        rows: null,
        lastline: null,
        performanceInterval: null,
    },
    data() {
        return {
            columns_name: null,
            assets: null,
        };
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        rows: function(newValue, oldValue) {
            if (newValue && newValue.length > 0) {
                this.assets = newValue.slice();
            }
        },
    },
    methods: {
        trendColorNegative: function(rendimento) {
            if (rendimento < 0) {
                var pesoBilanciato = Math.abs(rendimento) * 100;
                var value = pesoBilanciato / this.performanceInterval;
                return value + '%';
            }
        },
        trendColorPositive: function(rendimento) {
            if (rendimento > 0) {
                var pesoBilanciato = Math.abs(rendimento) * 100;
                var value = pesoBilanciato / this.performanceInterval;
                return value + '%';
            }
        },
        toggleAsset(asset) {
            var tempRows = this.assets.slice();
            tempRows.forEach(function(item) {
                if (item.name === asset.name) {
                    item.isOpen = !asset.isOpen;
                }
            });
            this.assets = tempRows;
        },
        toggleSubasset(asset, subasset) {
            var tempRows = this.assets.slice();
            tempRows.forEach(function(item) {
                if (item.name === asset.name) {
                    item.subassets.forEach(function(itemb) {
                        if (itemb.name === subasset.name) {
                            itemb.isOpen = !subasset.isOpen;
                        }
                    });
                }
            });
            this.assets = tempRows;
        },
        dullInstrumentColor: function(value) {
            if (value) {
                return color(value).fade(0.7);
            }
        },
    },
    created() {
        if (this.columns && this.columns.length > 0) {
            this.columns_name = this.columns.map(function(index) {
                return index.title;
            });
        }

        if (this.rows != null && this.rows.length > 0) {
            this.assets = this.rows.slice();
        }
    },
};
