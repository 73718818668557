var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"StyledButton",class:{
		'StyledButton--disabled': _vm.disabled,
		'StyledButton--loading': _vm.showLoading,
		'StyledButton--cobalt': _vm.color === 'cobalt',
		'StyledButton--berry': _vm.color === 'berry',
		'StyledButton--white': _vm.color === 'white',
	},on:{"click":_vm.click}},[_c('span',{staticClass:"StyledButton__label"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"StyledButton__spinner-wrapper"},[_c('spinner',{attrs:{"color":_vm.spinnerColor}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }