// prettier-ignore
/* eslint-disable no-unused-vars */
import $ from 'jquery';
import parsley from 'parsleyjs';
import store from '../store/onboarding';
import axios from 'axios';
import { parse } from 'libphonenumber-js';

const BASE_URL_MADIBA = '../../a/';
const BASE_URL_ONBOARDING = '../../onboarding/';

const parsleyConfiguration = {
    successClass: 'has-success',
    errorClass: 'has-error',
    classHandler: function(el) {
        return el.$element;
    },
    errorsWrapper: "<span class='help-block'></span>",
    errorTemplate: '<span></span>',
};

const setupValidation = function() {
    $('.on-an__form').parsley(parsleyConfiguration);
};

const validDate = function(value, requirement) {
    function isValidDate(s) {
        var bits = s.split('/');
        var y = parseInt(bits[2]);
        var m = parseInt(bits[1]);
        var d = parseInt(bits[0]);
        // Assume not leap year by default (note zero index for Jan)
        var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        // If evenly divisible by 4 and not evenly divisible by 100,
        // or is evenly divisible by 400, then a leap year
        if ((!(y % 4) && y % 100) || !(y % 400)) {
            daysInMonth[1] = 29;
        }
        return d <= daysInMonth[--m];
    }
    var date_day = document
        .getElementById('[data-source="' + requirement + '"][data-role="day"]')
        .val();
    var date_month = document
        .getElementById(
            '[data-source="' + requirement + '"][data-role="month"]'
        )
        .val();
    var date_year = document
        .getElementById('[data-source="' + requirement + '"][data-role="year"]')
        .val();
    var d = [date_day, date_month, date_year].join('/');
    var items = document.getElementById('[data-source="' + requirement + '"]');
    var isValid = isValidDate(d);
    var today = new Date();
    // checking Releasing Date is antecedent of current day
    if (requirement === 'id_document_releasing_date') {
        const currentDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
        const inputDate = new Date(
            date_year,
            parseInt(date_month) - 1,
            date_day
        );
        isValid = inputDate < currentDate;
    }
    // checking Expiring Date is subsequent of current day
    if (requirement === 'id_document_expiring_date') {
        const currentDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
        const inputDate = new Date(
            date_year,
            parseInt(date_month - 1),
            date_day
        );
        isValid = inputDate > currentDate;
    }
    if (isValid) {
        items.removeClass('parsley-error');
        document.getElementById('#' + requirement + '-error').empty();
    } else {
        items.addClass('parsley-error');
    }
    return isValid;
};

function extract_chars(str, start_pos, step) {
    var result = [];
    for (var i = start_pos; i < str.length; i += step) {
        result.push(str[i]);
    }
    return result;
}

function validate_tax_code(value) {
    var fiscal_code_upcase = value.toUpperCase().substring(0, 15);

    var even_position_char_map = {
        '0': 0,
        '1': 1,
        '2': 2,
        '3': 3,
        '4': 4,
        '5': 5,
        '6': 6,
        '7': 7,
        '8': 8,
        '9': 9,
        A: 0,
        B: 1,
        C: 2,
        D: 3,
        E: 4,
        F: 5,
        G: 6,
        H: 7,
        I: 8,
        J: 9,
        K: 10,
        L: 11,
        M: 12,
        N: 13,
        O: 14,
        P: 15,
        Q: 16,
        R: 17,
        S: 18,
        T: 19,
        U: 20,
        V: 21,
        W: 22,
        X: 23,
        Y: 24,
        Z: 25,
    };
    var odd_position_char_map = {
        '0': 1,
        '1': 0,
        '2': 5,
        '3': 7,
        '4': 9,
        '5': 13,
        '6': 15,
        '7': 17,
        '8': 19,
        '9': 21,
        A: 1,
        B: 0,
        C: 5,
        D: 7,
        E: 9,
        F: 13,
        G: 15,
        H: 17,
        I: 19,
        J: 21,
        K: 2,
        L: 4,
        M: 18,
        N: 20,
        O: 11,
        P: 3,
        Q: 6,
        R: 8,
        S: 12,
        T: 14,
        U: 16,
        V: 10,
        W: 22,
        X: 25,
        Y: 24,
        Z: 23,
    };
    var check_digit_map = {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E',
        5: 'F',
        6: 'G',
        7: 'H',
        8: 'I',
        9: 'J',
        10: 'K',
        11: 'L',
        12: 'M',
        13: 'N',
        14: 'O',
        15: 'P',
        16: 'Q',
        17: 'R',
        18: 'S',
        19: 'T',
        20: 'U',
        21: 'V',
        22: 'W',
        23: 'X',
        24: 'Y',
        25: 'Z',
    };
    var even_position_chars = extract_chars(fiscal_code_upcase, 1, 2);
    var odd_position_chars = extract_chars(fiscal_code_upcase, 0, 2);
    var even_chars_sum = $.map(even_position_chars, function(x) {
        return even_position_char_map[x];
    }).reduce(function(v, x) {
        return x + v;
    }, 0);
    var odd_chars_sum = $.map(odd_position_chars, function(x) {
        return odd_position_char_map[x];
    }).reduce(function(v, x) {
        return x + v;
    }, 0);
    var derived_ctrl_char =
        check_digit_map[(even_chars_sum + odd_chars_sum) % 26];
    var expected_ctrl_char = value.charAt(value.length - 1).toUpperCase();
    if (derived_ctrl_char === expected_ctrl_char) {
        return true;
    }
    return false;
}

// const isChild = function() {
//     window.Parsley.addValidator('childCheck', {
//         validateString: function(value) {
//             if (validate_tax_code(value)) {
//                 var year = parseInt(value.substring(6, 8));
//                 var current_year = new Date().getFullYear() - 2000;
//                 if (current_year < year) {
//                     return false;
//                 }
//                 return true;
//             }
//             return true;
//         },
//         messages: {
//             it: 'Il codice fiscale non risulta valido per un minorenne.',
//         },
//     });
// };

const MONTH_CODES = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'H',
    'L',
    'M',
    'P',
    'R',
    'S',
    'T',
];

const isAdult = function() {
    window.Parsley.addValidator('adultCheck', {
        validateString: function(codiceFiscale) {
            if (validate_tax_code(codiceFiscale)) {
                let code_year = codiceFiscale.substr(6, 2);
                let code_month = codiceFiscale.substr(8, 1);
                let code_day = parseInt(codiceFiscale.substr(9, 2));
                if (code_day > 31) {
                    code_day = code_day - 40;
                }

                let current_date = new Date();
                let current_year = current_date.getFullYear();
                let current_mounth = current_date.getMonth() + 1;
                let month = MONTH_CODES.indexOf(code_month) + 1;
                let current_day = current_date.getDate();
                // check is_2k
                if (code_year < current_year.toString().slice(2)) {
                    code_year = 2000 + Number(code_year);
                } else {
                    code_year = 1900 + Number(code_year);
                }
                if (current_year - code_year >= 18) {
                    return true;
                } else {
                    return false;
                } // T/F
            }
            return false;
        },
        messages: {
            en: 'Il codice fiscale non risulta valido per un adulto.',
            it: 'Il codice fiscale non risulta valido per un adulto.',
        },
    });
};

const addFiscalCodeValidator = function(total_cosigners) {
    var options = { 'dataType': 'application/json', 'accepts': 'application/json', 'X-CSRFToken': store.state.csrfToken };
    window.Parsley.addAsyncValidator('fiscalCode', function (xhr) {
        return 200 === xhr.status;
      }, `${BASE_URL_MADIBA}cf/{value}/${total_cosigners == 1 ? 'm' : 'p' }/`, options=options);
};

const addMobilePhoneValidator = function() {
    // Custom validator for phone number
    window.Parsley.addValidator('mobilePhone', {
        validateString: function(value) {
            var hasInternationalPrefix = /^(\+[0-9]{2}).*$/.test(value);
            var parsedNum = hasInternationalPrefix
                ? parse(value)
                : parse(value, 'IT');
            return parsedNum.hasOwnProperty('phone');
        },
        messages: {
            it: 'Il numero di cellulare non risulta valido.',
        },
    });
};

const addDateValidator = function() {
    window.Parsley.addValidator('validDate', {
        validateString: validDate,
        messages: {
            it: 'Verifica questa data.',
        },
    });
};

const addDistrictCityValidator = function() {
    window.Parsley.addValidator('district_with_city_elem_id', {
        validateString: function(value, requirement) {
            var req_elems = requirement.split(',');
            var relatedCityVal = document
                .getElementById('#' + req_elems[0])
                .val();
            var relatedCountryVal = document
                .getElementById('#' + req_elems[1])
                .val();
            var isValid = true;
            if (relatedCityVal !== '') {
                $.ajax({
                    url: '/onboarding/city_district_validation',
                    data: {
                        city: relatedCityVal,
                        country: relatedCountryVal,
                        district: value,
                    },
                    dataType: 'json',
                    type: 'get',
                    async: false,
                    success: function(data) {
                        isValid = data.valid;
                    },
                    error: function() {
                        isValid = false;
                    },
                });
            }
            return isValid;
        },
        messages: {
            it: 'Provincia non risulta valida.',
        },
    });
};

const addEmailValidator = function(value) {
    window.Parsley.addValidator('emailValidated', {
        validateString: function(value) {
            axios({
                params: {
                    username: value,
                },
                method: 'post',
                url: BASE_URL_ONBOARDING + 'check/',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': store.state.csrfToken,
                },
            })
                .then(function(response) {
                    if (response.status === 200) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch(e => {
                    return false;
                });
        },
    });
};

const removeValidation = function() {
    $('.on-an__form	')
        .parsley()
        .destroy();
};

export {
    setupValidation,
    addFiscalCodeValidator,
    addMobilePhoneValidator,
    addDateValidator,
    addDistrictCityValidator,
    addEmailValidator,
    removeValidation,
    isAdult,
    //isChild,
};
