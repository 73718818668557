import Vue from 'vue';
import Router from 'vue-router';

import compositionContainer from '../../containers/CompositionContainer/CompositionContainer.vue';
import trendContainer from '../../containers/TrendContainer/TrendContainer.vue';
import riskContainer from '../../containers/RiskContainer/RiskContainer.vue';

Vue.use(Router);

export default new Router({
    // Base: '/legacy/', /// Note to change the name of your subproject, which corresponds to your build. assets PublicPath
    mode: 'history',
    routes: [
        {
            name: 'private-area',
            path: '/wm/area-privata/',
            component: compositionContainer,
        },
        {
            name: 'trend',
            path: '/wm/area-privata/andamento',
            component: trendContainer,
        },
        {
            name: 'risk',
            path: '/wm/area-privata/rischio',
            component: riskContainer,
        },
    ],
});
