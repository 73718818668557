import store from '../../store/onboarding';
import {
  setupValidation,
  addFiscalCodeValidator,
  addMobilePhoneValidator,
  addEmailValidator,
  isAdult,
  //isChild
} from '../../services/validation';
import $ from 'jquery';
import _ from 'lodash';
import i18n from 'i18next';
import 'intl-tel-input';
import parsley from 'parsleyjs';


export default {
  beforeCreate() {
    store.state.isLoading = true;
    var form = this.$options.name;
    store.commit('CLEAN_FORM', { form });
  },
  created() {
    const cosigner = this.$store.state.current_cosigner;
    const with_beneficiary = this.$store.state.with_beneficiary;
    const form = this.$options.name;
    if (this.$store.state.wm) this.wm = true;
    if (this.$store.state.existing_customer) this.existing_customer = true;
    this.$store.dispatch('get_customer_data', { cosigner, form }).then(
      response => {
        store.state.isLoading = false;
        this.addValidation();
      },
      error => {
        store.state.isLoading = false;
      }
    );
  },
  computed: {
    personal_info: () => store.state.personal_info,
    isLoading: () => store.state.isLoading,
    compileButton: () => i18n.t('General.compileButton'),
    editButton: () => i18n.t('General.editButton'),
    emailAddress: () =>
      i18n.t('Components.PersonalInformations.emailAddress'),
    fiscalCode: () => i18n.t('Components.PersonalInformations.fiscalCode'),
    mobilePhone: () =>
      i18n.t('Components.PersonalInformations.mobilePhone'),
    familyLink: () => i18n.t('Components.PersonalInformations.familyLink'),
    name: () => i18n.t('Components.PersonalInformations.name'),
    previousFormButton: () => i18n.t('General.previousFormButton'),
    saveButton: () => i18n.t('General.saveButton'),
    surname: () => i18n.t('Components.PersonalInformations.surname'),
    iban: () => i18n.t('Components.PersonalInformations.iban'),
    id_family_link: {
      get: function() {
        var name = null;
        Array.from(store.state.constraints.family_link).forEach(
          function(item) {
            if (
              store.state.personal_info.family_link == item.value
            ) {
              name = item.label;
            }
          }
        );
        return name;
      },
      set: function(value) {
        this.id_family_link = value;
      },
    },
  },
  data: function() {
    return {
      cosigners: _.cloneDeep(store.state.cosigners),
      current_cosigner: _.cloneDeep(store.state.current_cosigner),
      with_beneficiary: _.cloneDeep(store.state.with_beneficiary),
      wm: false,
      existing_customer: false,
      show_error: false,
      error_msg: "",
      default_error_msg: "Alcuni dati inseriti sono già registrati nei nostri sistemi. Se si vuole procedere contattare il customer care al numero: 800 946 922",
      tooltip_visible: false,
      tooltip_taxcode_visible: false,
      family_link: store.state.constraints.family_link,
    };
  },
  destroyed() {
    // sessionStorage.setItem('breadcrumb', JSON.stringify({cosigner: store.state.current_cosigner, step: 3}))
    store.state.isLoading = true;
  },
  methods: {
    addValidation() {
      $('#mobile-phone').intlTelInput({ initialCountry: 'it' });
      $('.intl-tel-input').css('height', '43px');
      setupValidation();
      const c = this.$store.state.total_cosigners;
      addFiscalCodeValidator(c);
      addMobilePhoneValidator();
      addEmailValidator();
      isAdult();
      //isChild()
    },
    async nextForm() {
      const form = this.$options.name;
      const cosigner = store.state.current_cosigner;
      const property = 'personal_info';
      if (cosigner == 1 && this.existing_customer) {
        this.$router.push({ name: 'indirizzi' });
      } else {
        // Validate form for async validation with parsley
        const formIsValid = await $('.on-an__form')
          .parsley()
          .asyncIsValid()
          .then(r => true)
          .catch(e => false);
        if (formIsValid) {
          store
            .dispatch('save_data_async', {
              cosigner,
              form,
              property,
            })
            .then(response => {
              if (response.status !== 200) {
                this.show_error = true;
                this.error_msg = response.data?.reason ?? this.default_error_msg;
                this.$raven.captureException(
                  new Error(
                    'The response status id :' +
                    response.status
                  )
                );
              }
              else {
                this.$router.push({ name: 'indirizzi' });
              }
            })
            .catch(error => {
              this.$raven.captureException(
                new Error('The error is :' + error)
              );
              this.show_error = true;
              this.error_msg = error.response.data?.reason ?? this.default_error_msg;
            })
        } else {
          $('.on-an__form')
            .parsley()
            .validate();
        }
      }
    },
    back_to_previous_form() {
      store.state.isLoading = true;
      event.preventDefault();
      const form = 'personal_info';
      store.dispatch('back_to_previous_form', { form });
    },
    show_tooltip() {
      this.tooltip_visible = !this.tooltip_visible;
    },
    show_tooltip_taxcode() {
      this.tooltip_taxcode_visible = !this.tooltip_taxcode_visible;
    },
    setActiveFamilyLinkType(option) {
      if (option.value)
        store.state.personal_info.family_link = option.value;
    },
  },
  name: 'personal_info',
  template: '</personal-info>',
};
