import i18n from 'i18next';
import '../../services/i18n/i18n';
// import BarChart from '../../components/BarChart/BarChart.vue';
import AreaChart from '../../components/AreaChart/AreaChart.vue';
import Table from '../../components/Table/Table.vue';
import $ from 'jquery';
import store from '../../store/privateArea';
import axios from 'axios';

const BASE_URL = '/wm/api/v1/mandato/';

export default {
    name: 'RiskContainer',
    template: '<RiskContainer/>',
    components: {
        AreaChart,
        Table,
    },
    filters: {
        percentage: function(value) {
            if (value) {
                value = value.toString().replace('.', ',');
                return value + '%';
            }
        },
        clean: function(value) {
            if (value) {
                value = value.toString().replace('.', ',');
                return value;
            }
        },
    },
    data() {
        return {
            indicators: null,
        };
    },
    methods: {
        fetchData() {
            axios
                .get(this.url, {
                    headers: { 'X-CSRFToken': store.csrf_token },
                })
                .then(response => {
                    this.indicators = response.data.results[0];
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    props: {},
    computed: {
        disclaimer() {
            return i18n.t('General.disclaimer');
        },
        tooltip() {
            setTimeout(function() {
                $('[data-toggle="popover"]').popover({
                    html: true,
                    trigger: 'hover',
                });
            }, 500);
            return i18n.t('Containers.Riskcontainer.tooltip');
        },
        tooltip2() {
            return i18n.t('Containers.Riskcontainer.tooltip2');
        },
        durationTooltip() {
            return i18n.t('Containers.Riskcontainer.durationTooltip');
        },
        fx_usdTooltip() {
            return i18n.t('Containers.Riskcontainer.fx_usdTooltip');
        },
        yieldTooltip() {
            return i18n.t('Containers.Riskcontainer.yieldTooltip');
        },
        dividendTooltip() {
            return i18n.t('Containers.Riskcontainer.dividendTooltip');
        },
        title() {
            return i18n.t('Containers.Riskcontainer.title');
        },
    },
    created() {
        this.url = BASE_URL + store.mandato;
        this.url += '/asset/risk_indicators/';
        this.contract = store.getters.getCurrentContract;
        this.fetchData();
    },
    updated() {
        $('[data-toggle="popover"]').popover({ html: true, trigger: 'hover' });
        // console.log('UPDATED');
    },
};
