var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Table",class:{
		composition: _vm.$route.name === 'private-area',
		trend: _vm.$route.name === 'trend',
		risk: _vm.$route.name === 'risk',
	}},[(_vm.type === 'COMPOSITION')?_c('div',{staticClass:"Table__header"},[_c('span'),_vm._v(" "),_c('span',[_vm._v("Strumenti")]),_vm._v(" "),_c('span',[_vm._v("Peso")]),_c('span'),_vm._v(" "),_c('span',[_vm._v("Valore")]),_vm._v(" "),_c('span',[_vm._v("Costo")])]):_vm._e(),(_vm.type === 'TREND')?_c('div',{staticClass:"Table__header"},[_c('span'),_vm._v(" "),_c('span',[_vm._v("Strumenti")]),_vm._v(" "),_c('span',[_vm._v("Rendimento")]),_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]):_vm._e(),(_vm.type === 'RISK')?_c('div',{staticClass:"Table__header"},[_c('span'),_vm._v(" "),_c('span',[_vm._v("Strumenti")]),_c('span',[_vm._v("Contributo al rischio")]),_vm._v(" "),_c('span'),_c('span',[_vm._v("Volatilità")]),_vm._v(" "),_c('span')]):_vm._e(),(_vm.type === 'COMPOSITION')?_c('div',{staticClass:"Table__body"},_vm._l((_vm.rows),function(asset){return _c('div',{key:asset.nome,staticClass:"Table__asset"},[_c('div',{staticClass:"Table__asset__content",on:{"click":function($event){return _vm.toggleAsset(asset)}}},[_c('span',{staticClass:"Table__asset__content__color",style:({ backgroundColor: asset.color })}),_c('span',[_vm._v(_vm._s(asset.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(asset.peso)))]),_c('span',{staticClass:"Table__asset__content__percentage-line",class:[asset.nome === 'Liquidità' ? 'liquidita' : '']},[_c('span',{staticClass:"Table__asset__content__percentage-line__inner",style:({
							width: asset.peso + '%',
							backgroundColor: asset.color,
						})})]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(asset.valore)))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(asset.spese)))])]),_vm._l((asset.subassets),function(subasset){return _c('div',{key:subasset.nome,staticClass:"Table__subasset",class:[asset.isOpen ? 'show' : 'hide', '']},[_c('div',{staticClass:"Table__subasset__content",on:{"click":function($event){return _vm.toggleSubasset(asset, subasset)}}},[_vm._m(0,true),_c('span',[_vm._v(_vm._s(subasset.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(subasset.peso)))]),_c('span',{staticClass:"Table__subasset__content__percentage-line"},[_c('span',{staticClass:"Table__subasset__content__percentage-line__inner",style:({
								width: subasset.peso + '%',
								backgroundColor: _vm.dullAssetClassColor(
									asset.color
								),
							})})]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(subasset.valore)))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(subasset.spese)))])]),_c('div',{staticClass:"Table__instrument",class:[subasset.isOpen ? 'show' : 'hide', '']},[_vm._m(1,true)]),_vm._l((subasset.instruments),function(instrument){return _c('div',{key:instrument.nome,staticClass:"Table__instrument",class:[subasset.isOpen ? 'show' : 'hide', '']},[_c('div',{staticClass:"Table__instrument__content"},[_c('span',{staticClass:"Table__instrument__content__color",style:({
								backgroundColor: _vm.dullInstrumentColor(
									asset.color
								),
							})}),_c('span',[_vm._v(_vm._s(instrument.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(instrument.peso)))]),_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("currency_price")(instrument.prezzo_mercato)))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("clean")(instrument.quantita)))])]),_c('span',[_vm._v(_vm._s(_vm._f("currency")(instrument.valore))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(instrument.spese)))])])])})],2)})],2)}),0):_vm._e(),(_vm.type === 'TREND')?_c('div',{staticClass:"Table__asset"},_vm._l((_vm.rows),function(asset){return _c('div',{key:asset.nome,staticClass:"Table__asset"},[_c('div',{staticClass:"Table__asset__content",on:{"click":function($event){return _vm.toggleAsset(asset)}}},[_c('span',{staticClass:"Table__asset__content__color",style:({ backgroundColor: asset.color })}),_c('span',[_vm._v(_vm._s(asset.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(asset.rendimento)))]),_c('span'),_c('span',{staticClass:"Table__asset__content__performance__percentage-line trend"},[_c('span',{staticClass:"Table__asset__content__performance__percentage-line__negative"},[_c('span',{style:({
								width: _vm.trendColorNegative(asset.rendimento),
							})})]),_c('span',{staticClass:"Table__asset__content__performance__percentage-line__zero"}),_c('span',{staticClass:"Table__asset__content__performance__percentage-line__positive"},[_c('span',{style:({
								width: _vm.trendColorPositive(asset.rendimento),
							})})])]),_c('span')]),_vm._l((asset.subassets),function(subasset){return _c('div',{key:subasset.nome,staticClass:"Table__subasset",class:[asset.isOpen ? 'show' : 'hide', ''],on:{"click":function($event){return _vm.toggleSubasset(asset, subasset)}}},[_c('div',{staticClass:"Table__subasset__content"},[_vm._m(2,true),_c('span',[_vm._v(_vm._s(subasset.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(subasset.rendimento)))]),_c('span'),_c('span',{staticClass:"Table__subasset__content__performance__percentage-line trend"},[_c('span',{staticClass:"Table__subasset__content__performance__percentage-line__negative"},[_c('span',{style:({
									width: _vm.trendColorNegative(
										subasset.rendimento
									),
								})})]),_c('span',{staticClass:"Table__subasset__content__performance__percentage-line__zero"}),_c('span',{staticClass:"Table__subasset__content__performance__percentage-line__positive"},[_c('span',{style:({
									width: _vm.trendColorPositive(
										subasset.rendimento
									),
								})})])]),_c('span')]),_vm._l((subasset.instruments),function(instrument){return _c('div',{key:instrument.nome,staticClass:"Table__instrument",class:[subasset.isOpen ? 'show' : 'hide', '']},[_c('div',{staticClass:"Table__instrument__content"},[_c('span',{staticClass:"Table__instrument__content__color",style:({
								backgroundColor: _vm.dullInstrumentColor(
									asset.color
								),
							})}),_c('span',[_vm._v(_vm._s(instrument.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(instrument.rendimento)))]),_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])})],2)})],2)}),0):_vm._e(),(_vm.type === 'RISK')?_c('div',{staticClass:"Table__body"},_vm._l((_vm.rows),function(asset){return _c('div',{key:asset.nome,staticClass:"Table__asset"},[_c('div',{staticClass:"Table__asset__content",on:{"click":function($event){return _vm.toggleAsset(asset)}}},[_c('span',{staticClass:"Table__asset__content__color",style:({ backgroundColor: asset.color })}),_c('span',[_vm._v(_vm._s(asset.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(asset.contributo)))]),_c('span',{staticClass:"Table__asset__content__percentage-line"},[_c('span',{staticClass:"Table__asset__content__percentage-line__inner",style:({
							width: asset.contributo + '%',
							backgroundColor: asset.color,
						})})]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(asset.volatilita)))]),_c('span')]),_vm._l((asset.subassets),function(subasset){return _c('div',{key:subasset.nome,staticClass:"Table__subasset",class:[asset.isOpen ? 'show' : 'hide', '']},[_c('div',{staticClass:"Table__subasset__content",on:{"click":function($event){return _vm.toggleSubasset(asset, subasset)}}},[_vm._m(3,true),_c('span',[_vm._v(_vm._s(subasset.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(subasset.contributo)))]),_c('span',{staticClass:"Table__asset__content__percentage-line"},[_c('span',{staticClass:"Table__asset__content__percentage-line__inner",style:({
								width: subasset.contributo + '%',
								backgroundColor: asset.color,
							})})]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(subasset.volatilita)))]),_c('span')]),_vm._l((subasset.instruments),function(instrument){return _c('div',{key:instrument.nome,staticClass:"Table__instrument",class:[subasset.isOpen ? 'show' : 'hide', '']},[_c('div',{staticClass:"Table__instrument__content"},[_c('span',{staticClass:"Table__instrument__content__color",style:({
								backgroundColor: _vm.dullInstrumentColor(
									asset.color
								),
							})}),_c('span',[_vm._v(_vm._s(instrument.nome))]),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(instrument.contributo)))]),_c('span'),_c('span',[_vm._v(_vm._s(_vm._f("percentage")(instrument.volatilita)))]),_c('span')])])})],2)})],2)}),0):_vm._e(),(_vm.lastLine)?_c('div',{staticClass:"Table__body__last"},[(_vm.type === 'COMPOSITION')?_c('div',{staticClass:"Table__body__last__content"},[_c('span'),_vm._v(" "),_c('span'),('peso' in _vm.lastLine)?_c('span',[_vm._v(_vm._s(_vm.lastLine.peso))]):_vm._e(),_c('span'),('valore' in _vm.lastLine)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(_vm.lastLine.valore)))]):_vm._e(),('spese' in _vm.lastLine)?_c('span',[_vm._v(_vm._s(_vm.lastLine.spese)+"%")]):_vm._e()]):_vm._e(),(_vm.type === 'TREND')?_c('div',{staticClass:"Table__body__last__content"},[_c('span'),_vm._v(" "),_c('span'),('rendimento' in _vm.lastLine)?_c('span',[_vm._v(_vm._s(_vm._f("percentage")(_vm.lastLine.rendimento)))]):_vm._e(),_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]):_vm._e(),(_vm.type === 'RISK')?_c('div',{staticClass:"Table__body__last__content"},[_c('span'),_vm._v(" "),_c('span'),('contributo' in _vm.lastLine)?_c('span',[_vm._v(_vm._s(_vm._f("percentage")(_vm.lastLine.contributo)))]):_vm._e(),_c('span'),('volatilita' in _vm.lastLine)?_c('span',[_vm._v(_vm._s(_vm._f("percentage")(_vm.lastLine.volatilita)))]):_vm._e(),_c('span')]):_vm._e(),(_vm.type !== 'TREND')?_c('div',{staticClass:"Table__body__last__content__last-line"},[_c('span',[_vm._v("Dati riferiti al "+_vm._s(_vm._f("formatDate")(_vm.lastLine.ref_date)))])]):_vm._e()]):_vm._e(),(_vm.loading)?_c('img',{staticClass:"ajaxIndicator pblue",attrs:{"src":"/static/fe/images/icons/puff_blue.svg"}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('img',{attrs:{"src":"/static/fe/images/icons/open-subasset.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Table__instrument__content"},[_c('span',{}),_vm._v(" "),_c('span'),_vm._v(" "),_c('span'),_c('span',[_c('span',[_vm._v("Prezzo")]),_c('span',[_vm._v("Qt.")])]),_c('span'),_vm._v(" "),_c('span')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('img',{attrs:{"src":"/static/fe/images/icons/open-subasset.svg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('img',{attrs:{"src":"/static/fe/images/icons/open-subasset.svg","alt":""}})])
}]

export { render, staticRenderFns }