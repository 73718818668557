import '../../services/i18n/i18n';
import store from '../../store/privateArea';

export default {
    data() {
        return {
            selectedTime: 'ytd',
        };
    },
    methods: {
        setTime: function(choice, event) {
            this.selectedTime = choice;
            store.commit('setSelectedTime', choice);
        },
    },
    created() {
        store.commit('setSelectedTime', this.selectedTime);
    },
};
