import Card from '../Card/Card.vue';
import $ from 'jquery';

export default {
    props: {
        core: {
            percentage: 0,
            elements: [],
        },
        satellite: {
            percentage: 0,
            elements: [],
        },
    },
    methods: {
        format(value) {
            if (value % 10 == 0) {
                return String(value);
            } else {
                return String(value.toFixed(2)).replace('.', ',');
            }
        },
    },
    computed: {
        coreTooltip() {
            var text = '';
            var that = this;
            this.core.elements
                .sort(function(a, b) {
                    return a.percentage > b.percentage
                        ? -1
                        : b.percentage > a.percentage
                        ? 1
                        : 0;
                })
                .forEach(function(element) {
                    text +=
                        "<span style='color: #253858;opacity: 0.64;'>" +
                        element.label +
                        "</span><span style='margin-left:20px;font-weight: bold;color:#1d5b8f;'> " +
                        that.format(element.percentage) +
                        '%</span></br />';
                });
            return text;
        },
        satelliteTooltip() {
            var text = '';
            var that = this;
            this.satellite.elements
                .sort(function(a, b) {
                    return a.percentage > b.percentage
                        ? -1
                        : b.percentage > a.percentage
                        ? 1
                        : 0;
                })
                .forEach(function(element) {
                    text +=
                        "<span style='color: #253858;opacity: 0.64;'>" +
                        element.label +
                        "</span><span style='margin-left:20px;font-weight: bold;color:#1d5b8f;'> " +
                        that.format(element.percentage) +
                        '%</span></br />';
                });
            return text;
        },
    },
    created() {
        this.$nextTick(function() {
            $('[data-toggle="popover"]').popover({
                html: true,
                trigger: 'hover',
            });

            $('#satellite_bar').on('shown.bs.popover', function() {
                $('div.popover').css({ float: 'right', 'margin-left': '30%' });
            });

            $('#core_bar').on('shown.bs.popover', function() {
                $('div.popover').css({ float: 'center', 'margin-left': '0px' });
            });
        });
    },
    components: {
        Card,
    },
};
